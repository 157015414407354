import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import enTranslation from './locales/en/en.json'
import zhTranslation from './locales/en/zh-cn.json'
import zhHkTranslation from './locales/en/zh-hk.json'

export const resources = {
  en: {
    translation: enTranslation,
  },
  'zh-CN': {
    translation: zhTranslation,
  },
  'zh-HK': {
    translation: zhHkTranslation,
  },
} as const

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'zh-cn'],
    debug: process.env.NODE_ENV !== 'production',
    resources,
  })

export default i18n
