import { Dispatch, createSlice } from '@reduxjs/toolkit'
import { fetchCurrentProfileInfo } from './fetch'

export type CognitoUserInfo = {
  username: string
  attributes: {
    email: string
    email_verified: boolean
    sub: string
  }
}

export interface ProfileInfo {
  _id: string
  username: string
  firstName: string
  lastName: string
  dateOfBirth: string
  email: string
  profilePicture: string | number
  addr: Array<String>
  level: Number
  slug: string
  nickname: string
  description: string
  status: string
  dateCreated: string
  ecwidKey: any
  nfts: Array<NFT>
  twitterHandle: string
}

export interface IProfilePicture {
  pictureType: 'DEFAULT' | 'NFT'
  imageNumber?: number
  ownerUsername?: string
  tokenId?: String
  chainId?: Number
  contractAddress?: String
  tokenUri?: string
  imageUrl?: string
}

export interface NFT {
  _id: string
  ownerUsername: string
  tokenId: string
  project: {
    _id: string
  }
  imageUrl: string
  contractAddress: string
  chainId: number
  type: string
  tokenUri: string
  ownerType: string
  chipPublicKeyhash: string
  name: string
  id: string
}

export interface AccountState {
  networkUnavailable: boolean
  isAuthenticated: boolean
  tokenExpiry: number
  profile?: ProfileInfo
}

export const initialState: AccountState = {
  networkUnavailable: false,
  isAuthenticated: false,
  tokenExpiry: 0,
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setNetworkUnavailable: (state: AccountState, action) => {
      state.networkUnavailable = action.payload
    },
    setIsAuthenticated: (state: AccountState, action) => {
      state.isAuthenticated = action.payload
    },
    setTokenExpiry: (state: AccountState, action) => {
      state.tokenExpiry = action.payload
    },
    setProfileInfo: (state: AccountState, action) => {
      state.profile = action.payload
    },
  },
})

export const {
  setNetworkUnavailable,
  setIsAuthenticated,
  setTokenExpiry,
  setProfileInfo,
} = accountSlice.actions

export const getCurrentUserInfo = async (dispatch: Dispatch) => {
  try {
    const profileInfo = await fetchCurrentProfileInfo()
    // To fetch profile data
    dispatch(setProfileInfo(profileInfo))
  } catch (e) {
    dispatch(setProfileInfo(undefined))
    dispatch(setIsAuthenticated(false))
  }
}

export default accountSlice
