import { Typography, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import {
  createRef,
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

const SueProtocol = forwardRef(
  (
    props: {
      index: number | string
      title: string
      content: string
      setActive: Function
    },
    ref: any,
  ) => {
    const { index, title, content, setActive } = props

    useEffect(() => {
      if (!ref) return
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActive()
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.8 },
      )

      if (ref.current) {
        observer.observe(ref.current)
      }
    }, [ref, setActive])

    return (
      <Box ref={ref} minWidth='100%'>
        <FullWidthDiv
          borderTop={1}
          borderBottom={1}
          borderColor={'primary.main'}
        >
          <ContainedDiv display='flex' justifyContent='space-between'>
            <Typography
              textTransform={'uppercase'}
              variant='subtitle1'
              color='primary'
              fontWeight='bold'
              py={1}
            >
              {title}
            </Typography>
            <Typography variant='h3' color='primary' fontWeight='bold' py={1}>
              {Number(index) > 9 ? index : `0${index}`}
            </Typography>
          </ContainedDiv>
        </FullWidthDiv>
        <FullWidthDiv>
          <ContainedDiv>
            <Typography color='primary' textAlign='justify' py={2}>
              {content}
            </Typography>
          </ContainedDiv>
        </FullWidthDiv>
      </Box>
    )
  },
)

const SuePassMobile: React.FC = () => {
  const { t } = useTranslation()

  const videoRef = useRef<HTMLVideoElement>(null)
  const suePassMobileRef = useRef<HTMLDivElement>(null)

  const [protocolList, setProtocolList] = useState<
    {
      title: string
      content: string
    }[]
  >([])

  const [protocolRefGroup, setprotocolRefGroup] = useState<
    MutableRefObject<any>[]
  >([])

  const [activePage, setActivePage] = useState<number>(0)

  useEffect(() => {
    if (!suePassMobileRef) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef?.current?.play()
        }
      },
      { threshold: 0.8 },
    )

    if (suePassMobileRef.current) {
      observer.observe(suePassMobileRef.current)
    }
  }, [videoRef])

  useEffect(() => {
    const data = Object.entries(
      t('rainbowsue.suePass.protocolInfos', { returnObjects: true }),
    ).map(([key, value]) => ({ title: value.title, content: value.content }))
    const refs = data.map(() => createRef())

    setProtocolList(data)
    setprotocolRefGroup(refs)
  }, [t])

  useEffect(() => {
    let video = videoRef?.current

    video &&
      video.addEventListener(
        'click',
        () => videoRef.current && setControls(videoRef.current),
      )

    return () => {
      video &&
        video.removeEventListener('click', () => video && setControls(video))
    }
  }, [videoRef])

  const setControls = (videoRef: HTMLVideoElement) => {
    videoRef.setAttribute('controls', 'controls')
  }

  const handleScroll = useCallback(
    (index: number) => {
      const targetIndex = index >= protocolList.length ? 0 : index
      protocolRefGroup[targetIndex]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    },
    [protocolList, protocolRefGroup],
  )

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      height='100%'
      ref={suePassMobileRef}
    >
      <SectionHeader
        title={t('rainbowsue.suePass.stakingTitle')}
        mb='0'
        alignRight
      />
      <Box py={1} onClick={() => videoRef?.current?.play()}>
        <video
          ref={videoRef}
          src='/images/rainbowSue/rainbowsue_d-fin.mp4'
          style={{
            objectFit: 'cover',
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          poster='/images/rainbowSue/suepass-poster.png'
          controlsList='nodownload noremoteplayback'
          muted
          autoPlay
          loop
          playsInline
          onClick={() => {}}
        />
      </Box>
      <Box>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
          }}
        >
          {protocolList.map((protocol, index) => (
            <SueProtocol
              title={protocol.title}
              content={protocol.content}
              index={index + 1}
              key={`sue-protocol-${index}`}
              setActive={() => setActivePage(index)}
              ref={protocolRefGroup[index]}
            />
          ))}
        </Stack>
      </Box>
      <FullWidthDiv
        display='flex'
        justifyContent='space-between'
        borderTop={1}
        borderColor={'primary.main'}
        onClick={() => handleScroll(activePage + 1)}
      >
        <Typography
          variant='subtitle1'
          textTransform={'uppercase'}
          color='primary'
          fontWeight='bold'
          py={1}
          px={'4.5%'}
          display={'flex'}
          width={'100%'}
          justifyContent={'space-between'}
        >
          {activePage + 1 >= protocolList.length && <ArrowBackIos />}
          {activePage + 1 >= protocolList.length
            ? 'Back to start'
            : 'next'}{' '}
          {activePage + 1 < protocolList.length && <ArrowForwardIos />}
        </Typography>
      </FullWidthDiv>
    </Box>
  )
}

export default SuePassMobile
