import { useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Grid,
  styled,
  Stack,
  Divider,
  Button,
} from '@mui/material'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import Marquee from 'react-fast-marquee'

import { useGetInstagramMedia } from 'hooks/useInstagram'
import { TInstagramMediaUrl } from 'constants/types'
import useIsMobile from 'hooks/useIsMobile'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import { channelsLinks } from 'components/RainbowSue/navigations'
import { ScrollReveal } from './ScrollReveal'

const CustomStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflowX: 'scroll',
  paddingTop: 48,

  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.background.paper,
  },
}))

const isVideo = (mediaUrl: string): Boolean => {
  let result = false
  if (mediaUrl.includes('.mp4')) {
    result = true
  }

  return result
}

const Instagram = () => {
  const [data, setData] = useState<TInstagramMediaUrl[] | null>(null)
  const { getInstagramMedia, isFetched, isLoading } = useGetInstagramMedia()
  const isMobile = useIsMobile()

  useEffect(() => {
    const fetchInstagramMedia = async () => {
      const result = await getInstagramMedia()
      setData(result)
    }

    if (!isLoading && !isFetched) {
      fetchInstagramMedia()
    }
  }, [isLoading, isFetched, getInstagramMedia])

  const firstSection = data?.slice(0, Math.ceil(data?.length / 2))
  const secondSection = data?.slice(Math.ceil(data?.length / 2))

  return (
    <ScrollReveal>
      <Grid container px={0} pt={isMobile ? 10 : 0}>
        <Box display={'flex'} width={'100%'} px={0}>
          <Box
            component={ContainedDiv}
            display={'flex'}
            width={'100%'}
            mt={isMobile ? 2 : 0}
            mb={isMobile ? 10 : 0}
            sx={{ ...(isMobile && { mx: 2 }) }}
          >
            <Grid item md={6} xs={6} display={'flex'} alignItems={'center'}>
              <Typography
                variant='h1'
                color={'primary'}
                textTransform={'uppercase'}
              >
                Feed
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Box display='flex' justifyContent={'flex-end'}>
                <Button
                  href={channelsLinks[1].to}
                  target='_blank'
                  sx={
                    isMobile
                      ? {
                          color: 'primary.main',
                          borderRadius: '50% !important',
                          height: '80px',
                          width: '80px',
                          fontSize: '30px',
                          fontWeight: 'bold',
                          padding: '0px !important',
                          border: 1,
                          borderColor: 'primary.main',
                          marginBottom: '15px',
                        }
                      : {
                          color: 'primary.main',
                          borderRadius: '50% !important',
                          height: '120px',
                          width: '120px',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          padding: '10px !important',
                          border: 1,
                          borderColor: 'primary.main',
                          marginY: '90px',
                          ':hover': {
                            background:
                              'url(/images/rainbowSue/Utitlies_disc_1.png)',
                            backgroundSize: 'contain',
                            borderColor: 'transparent',
                            cursor: 'pointer',
                            color: '#000',
                          },
                        }
                  }
                >
                  <Typography variant='subtitle1'>
                    {isMobile ? (
                      <ArrowOutwardIcon fontSize='inherit' />
                    ) : (
                      'Follow Us'
                    )}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box
          width={'100%'}
          mx={2}
          borderTop={1}
          borderColor={'primary.main'}
          sx={{ overflow: 'hidden' }}
        >
          <Marquee gradient={false} speed={60} direction='right'>
            <CustomStack
              direction='row'
              spacing={isMobile ? 3 : 10}
              divider={
                <Divider
                  sx={{
                    borderColor: 'primary.main',
                    height: isMobile ? '200px' : '264px',
                  }}
                  light
                  orientation='vertical'
                  flexItem
                />
              }
            >
              {firstSection?.map((content, index) => {
                return (
                  <Grid
                    container
                    maxHeight={'250px'}
                    maxWidth={'250px'}
                    key={`instagram-first-${index}`}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      alignSelf='center'
                    >
                      {isVideo(content.media_url) ? (
                        <video
                          src={content.media_url}
                          style={{
                            width: isMobile ? '172px' : '216px',
                            height: isMobile ? '172px' : '216px',
                            objectFit: 'cover',
                          }}
                          autoPlay
                          muted
                          playsInline
                          loop
                        >
                          <source src={content.media_url} type='video/mp4' />
                        </video>
                      ) : (
                        <img
                          src={content.media_url}
                          width='100%'
                          style={{
                            width: isMobile ? '172px' : '216px',
                            height: isMobile ? '172px' : '216px',
                            objectFit: 'cover',
                          }}
                          alt={content.permalink}
                        />
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </CustomStack>
            <Divider
              sx={{
                marginTop: '45px',
                marginLeft: isMobile ? '25px' : '70px',
                marginRight: isMobile ? '25px' : '70px',
                borderColor: 'primary.main',
                height: isMobile ? '200px' : '264px',
              }}
              light
              orientation='vertical'
              flexItem
            />
          </Marquee>
          <Divider
            sx={{
              paddingTop: '50px',
              width: '100%',
              borderColor: 'primary.main',
            }}
            light
            orientation='horizontal'
            flexItem
          />
          <Marquee gradient={false} speed={60} direction='left'>
            <Divider
              sx={{
                marginTop: '45px',
                marginLeft: isMobile ? '25px' : '70px',
                marginRight: isMobile ? '25px' : '70px',
                borderColor: 'primary.main',
                height: isMobile ? '200px' : '264px',
              }}
              light
              orientation='vertical'
              flexItem
            />
            <CustomStack
              direction='row'
              spacing={isMobile ? 3 : 10}
              divider={
                <Divider
                  sx={{
                    borderColor: 'primary.main',
                    height: isMobile ? '200px' : '264px',
                  }}
                  light
                  orientation='vertical'
                  flexItem
                />
              }
            >
              {secondSection?.map((content, index) => {
                return (
                  <Grid
                    container
                    maxHeight={'250px'}
                    maxWidth={'250px'}
                    key={`instagram-first-${index}`}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      alignSelf='center'
                    >
                      {isVideo(content.media_url) ? (
                        <video
                          src={content.media_url}
                          style={{
                            width: isMobile ? '172px' : '216px',
                            height: isMobile ? '172px' : '216px',
                            objectFit: 'cover',
                          }}
                          autoPlay
                          muted
                          playsInline
                          loop
                        >
                          <source src={content.media_url} type='video/mp4' />
                        </video>
                      ) : (
                        <img
                          src={content.media_url}
                          width='100%'
                          style={{
                            width: isMobile ? '172px' : '216px',
                            height: isMobile ? '172px' : '216px',
                            objectFit: 'cover',
                          }}
                          alt={content.permalink}
                        />
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </CustomStack>
          </Marquee>
        </Box>
      </Grid>
    </ScrollReveal>
  )
}

export default Instagram
