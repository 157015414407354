import { useState } from 'react'
import { Button, Fade, Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ModalRainbowsue from 'components/RainbowSue/Modal'
import SectionHeader from './SectionHeader'
import { FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import { Remove } from '@mui/icons-material'

const PartnershipMobile = () => {
  const [activePartnership, setActivePartnership] = useState<{
    id: number
    name: string
    occupation: string
    image: string
  } | null>(null)

  const { t } = useTranslation()

  const partnerships = Object.entries(
    t('rainbowsue.partnership', { returnObjects: true }),
  ).map(([key, value]) => ({
    id: value.id,
    name: value.name,
    occupation: value.occupation,
    image: value.image,
  }))

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onButtonClick = (id: string) => {
    const data = partnerships.find((element) => element.id === id)
    if (data) {
      setActivePartnership(data)
    }
    handleOpen()
  }

  return (
    <>
      <Grid container>
        <SectionHeader title='partnerships' alignRight />
        <Grid item container mt={4}>
          {partnerships.map((partnership, index) => (
            <Grid
              item
              component={FullWidthDiv}
              md={12}
              borderTop={index === 0 ? 1 : 0}
              borderBottom={1}
              borderColor={'primary.main'}
              width='100%'
              key={`partnership-mobile-${partnership.id}`}
              py={'4px'}
            >
              <Button onClick={() => onButtonClick(partnership.id)}>
                <Typography
                  variant='subtitle1'
                  color='primary'
                  fontWeight='bold'
                  textTransform='uppercase'
                >
                  {partnership.name}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Keep this hidden until fixed with partner */}
      {/* <Box marginTop={10} width='100%' borderTop={'1px solid #fff'}>
        <Marquee gradient={false} speed={60} direction='right'>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
        </Marquee>
      </Box> */}
      <ModalRainbowsue open={open} handleClose={handleClose}>
        <Fade in={open}>
          <Grid container>
            <Grid item xs={6}>
              <IconButton
                aria-label='hamburgerBar'
                size='large'
                sx={{
                  border: 1,
                  borderColor: 'primary.main',
                  height: 'fit-content',
                }}
                onClick={() => handleClose()}
              >
                <Remove color='primary' />
              </IconButton>
            </Grid>
            <Grid
              item
              my={2}
              py={1}
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                src={activePartnership?.image}
                alt={activePartnership?.name}
                style={{
                  height: '300px',
                  aspectRatio: 1,
                  maxWidth: '300px',
                  objectFit: 'cover',
                  paddingTop: '45px',
                }}
              />
            </Grid>
            <Grid
              item
              my={2}
              py={1}
              xs={12}
              borderTop={1}
              borderBottom={1}
              borderColor={'primary.main'}
            >
              <Typography
                variant='subtitle1'
                color='primary'
                fontWeight='bold'
                textTransform='uppercase'
              >
                {activePartnership?.name}
              </Typography>
            </Grid>
            <Grid item my={2} py={1} xs={12}>
              <Typography
                variant='subtitle2'
                color='primary'
                fontWeight='light'
                textAlign='justify'
              >
                {activePartnership?.occupation}
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </ModalRainbowsue>
    </>
  )
}

export default PartnershipMobile
