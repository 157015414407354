import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import useToast from 'state/toast/hooks'

const Toast: React.FC = () => {
  const { hideToast, toastState } = useToast()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    hideToast()
  }

  return (
    <Snackbar
      open={toastState.open}
      autoHideDuration={toastState.duration}
      onClose={handleClose}
      anchorOrigin={
        toastState.direction || { vertical: 'top', horizontal: 'right' }
      }
    >
      <Alert
        onClose={handleClose}
        severity={toastState.type}
        sx={{ width: '100%' }}
      >
        {toastState.message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
