import Axios from 'axios'
import { TProject } from 'constants/project'
import { TRedeemableTokenInfo } from 'constants/redeemableToken'

const PROJECT_ENDPOINT = '/api/v1/project'

export type TProjectUpdateRequestBody = {
  name: string
  description?: string
  projectOwners?: string[]

  contractAddress?: string
  chainId?: number
  type?: string // ERC721, ERC1155, ERC5791

  nfcLocked?: boolean
  nfcClaimType?: string

  rarities?: string[]
}

export type TProjectGetQuery = {
  chainId?: number
  type?: string
  contractAddress?: string
  nfcClaimType?: string
  nfcLocked?: boolean
}

export const ProjectApi = {
  createProject: async function (projectInfo: TProjectUpdateRequestBody) {
    try {
      await Axios.post(PROJECT_ENDPOINT, projectInfo)
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getProjects: async function (query: TProjectGetQuery): Promise<TProject[]> {
    try {
      const result = await Axios.get(PROJECT_ENDPOINT, { params: query })
      return result.data.projects
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getRedeemableVoucherInfoGroup: async function (): Promise<
    TRedeemableTokenInfo[]
  > {
    try {
      const endpoint = `${PROJECT_ENDPOINT}/vouchers`

      const result = await Axios.get(endpoint)

      return result.data.vouchers
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getRedeemableVoucherInfo: async function (
    projectCode: null | string = null,
  ): Promise<TRedeemableTokenInfo> {
    try {
      const endpoint = `${PROJECT_ENDPOINT}/vouchers/${projectCode}`

      const result = await Axios.get(endpoint)

      return result.data.voucher
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
