import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import React from 'react'
import SectionHeader from './SectionHeader'
import { RoundedButton } from './UtilitiesDesktop'
import {
  DarkRainbowColorValue,
  RainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import useIsMobile from 'hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { ScrollReveal } from './ScrollReveal'

const DesktopContentBox: React.FC<{
  subtitle: string
  title: string
  active?: boolean
  reversed?: boolean
}> = ({ subtitle, title, active, reversed }) => {
  const isLightMode = useTheme().palette.mode === 'light'
  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 2,
        display: 'flex',
        flexDirection: 'column-reverse',
        ...(reversed && {
          flexDirection: 'column',
        }),
        ...(active && {
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'primary.main',
        }),
      }}
    >
      <Typography
        variant='subtitle2'
        color='primary'
        sx={{ ...(reversed ? { mb: 2 } : { mt: 2 }) }}
      >
        {subtitle}
      </Typography>
      <Typography
        variant='subtitle1'
        color='primary'
        textTransform={'uppercase'}
        fontWeight={'bold'}
        sx={{
          ...(active && {
            cursor: 'pointer',
            background: isLightMode ? DarkRainbowColorValue : RainbowColorValue,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            animation: 'textAnimate 5s linear infinite reverse',
            '@keyframes textAnimate': {
              from: {
                filter: 'hue-rotate(0deg)',
                backgroundPositionX: '0%',
              },
              to: {
                filter: 'hue-rotate(360deg)',
                backgroundPositionX: '100%',
              },
            },
          }),
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

const MobileContentBox: React.FC<{
  subtitle: string
  title: string
  active?: boolean
}> = ({ subtitle, title, active }) => {
  const isLightMode = useTheme().palette.mode === 'light'
  return (
    <Box
      sx={{
        textAlign: 'left',
        pt: 2,
        pl: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='subtitle2' color='primary' sx={{ mb: 1 }}>
        {subtitle}
      </Typography>
      <Typography
        variant='subtitle1'
        color='primary'
        textTransform={'uppercase'}
        fontWeight={'bold'}
        sx={{
          ...(active && {
            cursor: 'pointer',
            background: isLightMode ? DarkRainbowColorValue : RainbowColorValue,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            animation: 'textAnimate 5s linear infinite reverse',
            '@keyframes textAnimate': {
              from: {
                filter: 'hue-rotate(0deg)',
                backgroundPositionX: '0%',
              },
              to: {
                filter: 'hue-rotate(360deg)',
                backgroundPositionX: '100%',
              },
            },
          }),
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

const Roadmap = () => {
  const current = 1
  const isMobile = useIsMobile()

  const { t } = useTranslation()
  const data: IRMData[] = t('rainbowsue.roadmap.data', {
    returnObjects: true,
  }) as []

  return (
    <FullWidthDiv sx={{ margin: '0 !important' }}>
      <SectionHeader
        title={'Roadmap'}
        alignRight
        mb={isMobile ? '45px' : '88px'}
      />
      <ScrollReveal>
        {isMobile ? (
          <Stack direction={'row'} sx={{ mb: 8 }}>
            <Box
              component={'span'}
              sx={{
                flexGrow: 1,
                borderLeft: 1,
                borderBottom: 1,
                borderColor: 'primary.main',
                position: 'relative',
                height: 'auto',
                top: '0',
                left: '51px',
                bottom: '0',
                zIndex: 100,
              }}
            ></Box>
            <Stack
              direction={'column'}
              sx={{
                zIndex: 1000,
                width: '100%',
              }}
            >
              {data.map((event, index) => (
                <Box
                  display={'flex'}
                  alignItems={'start'}
                  whiteSpace='nowrap'
                  flexDirection={'row'}
                  mx={'31px'}
                  sx={{ minHeight: '115px' }}
                >
                  <Box
                    sx={{ py: 2, backgroundColor: 'background.paper', mr: 2 }}
                  >
                    <RoundedButton
                      index={index}
                      active={current === index}
                      setPage={() => {}}
                      size={48}
                    />
                  </Box>
                  <Stack>
                    <MobileContentBox
                      subtitle={event.date}
                      title={event.label}
                      active={current === index}
                    />
                  </Stack>
                </Box>
              ))}
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'start'}
                textAlign={'start'}
              >
                <Typography
                  sx={{ backgroundColor: 'background.paper', pl: 4, pt: 2 }}
                  variant='subtitle1'
                  color='primary'
                  textTransform={'uppercase'}
                >
                  {' '}
                  {t('rainbowsue.roadmap.commingsoon')}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack direction={'column'} sx={{ mx: 4, mb: 8 }}>
            <Box
              component={'span'}
              sx={{
                flexGrow: 1,
                borderBottom: 1,
                borderColor: 'primary.main',
                margin: '5px',
                position: 'relative',
                top: '184px',
                zIndex: 1000,
              }}
            ></Box>
            <Stack
              direction={'row'}
              sx={{
                zIndex: 1000,
                overflowX: 'auto',
                width: '100%',
                pb: 4,
                '::-webkit-scrollbar': {
                  height: '8px',
                  border: 1,
                  borderColor: 'primary.main',
                },
                '::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              {data.map((event, index) => (
                <Box
                  display={'flex'}
                  flex={'1 0 225px'}
                  alignItems={'center'}
                  whiteSpace='nowrap'
                  flexDirection={index % 2 === 0 ? 'column' : 'column-reverse'}
                  mx={4}
                  sx={{
                    ...(index % 2 === 0
                      ? {
                          marginTop: 'calc(200px - 46px)',
                        }
                      : {
                          marginBottom: 'calc(200px - 46px)',
                        }),
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      backgroundColor: 'background.paper',
                      zIndex: 100,
                    }}
                  >
                    <Box
                      sx={{
                        p: '6px',
                        ...(current === index && {
                          border: 1,
                          borderColor: 'primary.main',
                          borderRadius: '100%',
                        }),
                      }}
                    >
                      <RoundedButton
                        index={index}
                        active={current === index}
                        setPage={() => {}}
                        size={48}
                      />
                    </Box>
                  </Box>
                  {current === index && (
                    <Divider
                      orientation='vertical'
                      sx={{
                        position: 'relative',
                        height: '12px',
                        borderColor: 'primary.main',
                        zIndex: 2,
                      }}
                    />
                  )}
                  <Stack>
                    <DesktopContentBox
                      reversed={index % 2 === 0}
                      subtitle={event.date}
                      title={event.label}
                      active={current === index}
                    />
                  </Stack>
                </Box>
              ))}
              <Box
                height='360px'
                display={'flex'}
                alignItems={'center'}
                justifyContent={'end'}
                textAlign={'end'}
                flex={'1 0 225px'}
              >
                <Typography
                  sx={{ backgroundColor: 'background.paper', pl: 4 }}
                  variant='subtitle1'
                  color='primary'
                >
                  {' '}
                  {t('rainbowsue.roadmap.commingsoon')}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        )}
      </ScrollReveal>
    </FullWidthDiv>
  )
}

export default Roadmap

interface IRMData {
  date: string
  label: string
}
