import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  AccordionProps,
  AccordionSummaryProps,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

import React from 'react'
import useIsMobile from 'hooks/useIsMobile'
import {
  DarkRainbowColorValue,
  RainbowColorValue,
} from 'components/RainbowSue/RainbowLink'

import sanitizeHtml from 'sanitize-html'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import SectionHeader from './SectionHeader'
import { aspectRatioWidthValue } from 'theme/rainbowsueTheme'
import { useTranslation } from 'react-i18next'
import { ScrollReveal } from './ScrollReveal'

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    backgroundColor: 'transparent',
  },
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&:last-child #panel4a-header': {
    borderBottom: 'none',
  },
  '&.Mui-expanded:last-child #panel4a-header': {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}))

const CustomSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
  '&.Mui-expanded': {
    borderImage:
      theme.palette.mode === 'light'
        ? DarkRainbowColorValue
        : RainbowColorValue,
    borderImageSlice: 1,
    animation: 'textAnimate 5s linear infinite reverse',
  },
  '&.Mui-expanded + div > div > div > div> div': {
    borderImage:
      theme.palette.mode === 'light'
        ? DarkRainbowColorValue
        : RainbowColorValue,
    borderImageSlice: 1,
    animation: 'textAnimate 5s linear infinite reverse',
  },
  '&.Mui-expanded > div:first-child': {
    background:
      theme.palette.mode === 'light'
        ? DarkRainbowColorValue
        : RainbowColorValue,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    animation: 'textAnimate 5s linear infinite reverse',
    '@keyframes textAnimate': {
      from: {
        filter: 'hue-rotate(0deg)',
        backgroundPositionX: '0%',
      },
      to: {
        filter: 'hue-rotate(360deg)',
        backgroundPositionX: '100%',
      },
    },
  },
}))

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.main}`,
  animation: 'none',
}))

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <svg
        width={0}
        height={0}
        visibility={'hidden'}
        style={{ position: 'absolute' }}
      >
        <linearGradient id='linearColors'>
          <stop offset={'0%'} stopColor='#31EAE3' />
          <stop offset={'20%'} stopColor='#E1F664' />
          <stop offset={'40%'} stopColor='#FEB0FE' />
          <stop offset={'60%'} stopColor='#ABB3FC' />
          <stop offset={'80%'} stopColor='#5DF7A4' />
          <stop offset={'100%'} stopColor='#58C4F6' />
        </linearGradient>
      </svg>
      <div className='expandIconWrapper'>
        <Remove sx={{ fill: 'url(#linearColors)' }} />
      </div>
      <div className='collapsIconWrapper'>
        <Add color='primary' />
      </div>
    </Box>
  )
}

const FAQ = () => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const data: IFaq[] = t('rainbowsue.faq', { returnObjects: true }) as []

  return (
    <FullWidthDiv sx={{ margin: '0 !important' }}>
      <SectionHeader
        title={isMobile ? 'FAQ' : 'Frequently Asked Questions'}
        alignRight
      />
      <ScrollReveal>
        <ContainedDiv sx={{ flexDirection: 'column' }}>
          {data.map((faq, index) => (
            <CustomAccordion key={index}>
              <CustomSummary
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{ padding: 0, mx: 'auto', maxWidth: aspectRatioWidthValue }}
              >
                <ContainedDiv
                  sx={{
                    maxWidth: '100vw',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    textTransform='uppercase'
                    fontWeight={'bold'}
                    my={isMobile ? 0 : 2}
                  >
                    {faq.title}
                  </Typography>
                  <CustomExpandIcon />
                </ContainedDiv>
              </CustomSummary>
              <CustomAccordionDetails
                sx={{
                  mx: 'auto',
                  maxWidth: aspectRatioWidthValue,
                  paddingLeft: 0,
                  paddingRight: 0,
                  animation: 'none !important',
                }}
              >
                <ContainedDiv
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{ py: 2 }}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(faq.description),
                    }}
                  />
                </ContainedDiv>
              </CustomAccordionDetails>
            </CustomAccordion>
          ))}
        </ContainedDiv>
      </ScrollReveal>
    </FullWidthDiv>
  )
}

export default FAQ

interface IFaq {
  title: string
  description: string
}
