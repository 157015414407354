import {
  Box,
  Grid,
  Stack,
  Typography,
  ButtonBase,
  useTheme,
} from '@mui/material'
import RainbowLink from 'components/RainbowSue/RainbowLink'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import {
  channelsLinks,
  companyLinks,
  navigations,
} from 'components/RainbowSue/navigations'
import useIsMobile from 'hooks/useIsMobile'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { aspectRatioWidthValue } from 'theme/rainbowsueTheme'

const Footer = () => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const isLightMode = useTheme().palette.mode === 'light'

  return (
    <>
      <FullWidthDiv
        width={'100%'}
        sx={{
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'primary.main',
          py: { xs: 2, md: 6 },
          mt: '128px',
        }}
      >
        <Grid
          component={!isMobile ? ContainedDiv : Grid}
          width={'auto'}
          container
          rowSpacing={6}
        >
          <Grid
            component={isMobile ? ContainedDiv : Grid}
            item
            xs={12}
            md={7}
            sx={{
              paddingLeft: '12px',
              ...(isMobile && {
                pb: 2,
                borderBottom: 1,
                borderColor: 'primary.main',
              }),
            }}
          >
            <img
              style={{ ...(isMobile && { marginLeft: '-14px' }) }}
              width='auto'
              height='50px'
              src={
                isLightMode
                  ? 'images/rainbowSue/rsLogoPink.png'
                  : '/images/rainbowSue/rsLogo.png'
              }
              alt='rsLogo'
            />
          </Grid>
          {isMobile ? (
            <>
              <Grid item container xs={12}>
                <ContainedDiv
                  sx={{
                    width: '100%',
                  }}
                >
                  <Grid item xs={12}>
                    <Stack pb={4}>
                      <Typography
                        color={'primary'}
                        variant='subtitle2'
                        textTransform={'uppercase'}
                        fontWeight={'bold'}
                        pb={4}
                      >
                        {t('rainbowsue.footer.sitemap')}
                      </Typography>
                      {navigations.map((siteMap) => (
                        <RainbowLink
                          href={`#${siteMap.to}`}
                          color={'primary'}
                          variant='subtitle2'
                          underline='none'
                          mb={1}
                        >
                          {siteMap.label}
                        </RainbowLink>
                      ))}
                    </Stack>
                    <Stack pb={4}>
                      <Typography
                        color={'primary'}
                        variant='subtitle2'
                        textTransform={'uppercase'}
                        fontWeight={'bold'}
                        pb={4}
                      >
                        {t('rainbowsue.footer.company')}
                      </Typography>
                      {companyLinks.map((company) => (
                        <RainbowLink
                          href={
                            typeof company.to === 'string'
                              ? company.to
                              : isLightMode
                              ? company.to[0]
                              : company.to[1]
                          }
                          color={'primary'}
                          variant='subtitle2'
                          underline='none'
                          mb={1}
                        >
                          {company.label}
                        </RainbowLink>
                      ))}
                    </Stack>
                    <Stack pb={4}>
                      <Typography
                        color={'primary'}
                        variant='subtitle2'
                        textTransform={'uppercase'}
                        fontWeight={'bold'}
                        pb={4}
                      >
                        {t('rainbowsue.footer.channels')}
                      </Typography>
                      {channelsLinks.map((channel) => (
                        <RainbowLink
                          href={channel.to}
                          color={'primary'}
                          variant='subtitle2'
                          underline='none'
                          mb={1}
                        >
                          {channel.label}
                        </RainbowLink>
                      ))}
                    </Stack>
                  </Grid>
                </ContainedDiv>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6} md={2}>
                <Stack>
                  <Typography
                    color={'primary'}
                    variant='subtitle2'
                    textTransform={'uppercase'}
                    fontWeight={'bold'}
                    pb={4}
                  >
                    {t('rainbowsue.footer.sitemap')}
                  </Typography>
                  {navigations.map((siteMap) => (
                    <RainbowLink
                      href={`#${siteMap.to}`}
                      color={'primary'}
                      variant='subtitle2'
                      underline='none'
                      pb={1}
                    >
                      {siteMap.label}
                    </RainbowLink>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={6} md={2}>
                <Stack>
                  <Typography
                    color={'primary'}
                    variant='subtitle2'
                    textTransform={'uppercase'}
                    fontWeight={'bold'}
                    pb={4}
                  >
                    {t('rainbowsue.footer.company')}
                  </Typography>
                  {companyLinks.map((company) => (
                    <RainbowLink
                      href={
                        typeof company.to === 'string'
                          ? company.to
                          : isLightMode
                          ? company.to[0]
                          : company.to[1]
                      }
                      color={'primary'}
                      variant='subtitle2'
                      underline='none'
                      target='_blank'
                      rel='noopener'
                      pb={1}
                    >
                      {company.label}
                    </RainbowLink>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={1}>
                <Stack>
                  <Typography
                    color={'primary'}
                    variant='subtitle2'
                    textTransform={'uppercase'}
                    fontWeight={'bold'}
                    pb={4}
                  >
                    {t('rainbowsue.footer.channels')}
                  </Typography>
                  {channelsLinks.map((channel) => (
                    <RainbowLink
                      href={channel.to}
                      color={'primary'}
                      variant='subtitle2'
                      underline='none'
                      pb={1}
                      target='_blank'
                      rel='noopener'
                    >
                      {channel.label}
                    </RainbowLink>
                  ))}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </FullWidthDiv>
      <Box
        width={'95%'}
        maxWidth={aspectRatioWidthValue}
        sx={{ py: 6, ml: isMobile ? 2 : 'auto', mr: 'auto' }}
      >
        <ButtonBase
          href='https://labelled.io'
          target='_blank'
          rel='noopener'
          sx={{ width: '100%' }}
        >
          <img
            src={
              isLightMode
                ? 'images/rainbowSue/labelled-pink.svg'
                : 'images/Logo-white.svg'
            }
            alt='labelled.io logo'
            width={isMobile ? '95%' : '100%'}
          />
        </ButtonBase>
      </Box>
      <Box
        width={'100%'}
        sx={{ borderTop: 1, borderColor: 'primary.main', pt: 3 }}
      >
        <ContainedDiv>
          <Grid container sx={{ px: 1 }}>
            <Grid item xs={12} md={6}>
              <RainbowLink
                href={'https://passport.labelled.io/privacypolicy'}
                color={'primary'}
                underline='none'
                variant='h2'
                target='_blank'
                rel='noopener'
              >
                {t('rainbowsue.footer.privacypolicy')}
              </RainbowLink>
            </Grid>
            <Grid item xs={12} md={6} mb={isMobile ? 4 : 'auto'}>
              <Typography
                variant='h2'
                color={'primary'}
                textAlign={{ xs: 'left', md: 'right' }}
              >
                {t('rainbowsue.footer.copyright')}
              </Typography>
            </Grid>
          </Grid>
        </ContainedDiv>
      </Box>
    </>
  )
}

export default Footer
