import React, { useContext } from 'react'
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  IconButton,
  Stack,
  Link,
} from '@mui/material'

import { Remove, LightModeOutlined } from '@mui/icons-material'

import { channelsLinks } from './navigations'
import RainbowLink, { RainbowColorValue } from './RainbowLink'
import useIsMobile from 'hooks/useIsMobile'
import { useTheme } from '@mui/material'
import { NavIconButton } from './Header'
import { ColorModeContext } from 'views/RainbowSue'
import { mintingUrl } from 'constants/rainbowSue'

const nav = [
  {
    to: 'aboutNft',
    label: 'about rainbowsue NFT',
  },
  {
    to: 'artist',
    label: 'the artist',
  },
  {
    to: 'roadmap',
    label: 'roadmap',
  },
  {
    to: 'suePass',
    label: 'sue pass',
  },
  {
    to: 'utilities',
    label: 'utilities',
  },
  {
    to: 'faq',
    label: 'frequently asked questions',
  },
  {
    to: 'partnership',
    label: 'partnerships',
  },
  {
    to: 'instagram',
    label: 'Feed',
  },
]

const NavDrawer = ({ handleClose }: IProps) => {
  const isMobile = useIsMobile()
  const isLightMode = useTheme().palette.mode === 'light'

  const colorMode = useContext(ColorModeContext)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          px: 3,
          zIndex: 1000000,
          ...(!isMobile && { minWidth: '375px' }),
        }}
      >
        <Stack direction='row' py={3} px={2}>
          <IconButton
            aria-label='hamburgerBar'
            size='large'
            sx={{
              border: 1,
              borderColor: isLightMode ? 'secondary.main' : 'primary.main',
              height: 'fit-content',
              ':hover': {
                background: 'url(/images/rainbowSue/Utitlies_disc_1.png)',
                backgroundSize: 'contain',
                cursor: 'pointer',
                color: '#000',
                borderColor: 'transparent',
              },
              ':hover svg': {
                color: '#000',
              },
            }}
            onClick={() => handleClose()}
          >
            <Remove color={isLightMode ? 'secondary' : 'primary'} />
          </IconButton>
          <Box
            sx={{
              ...(isMobile
                ? { marginLeft: 'auto', marginRight: '0' }
                : { marginLeft: '25px' }),
            }}
          >
            <img
              width='auto'
              height='50px'
              src='/images/rainbowSue/rsLogo.png'
              alt='rsLogo'
            />
          </Box>
        </Stack>
        <List
          id='rsNavigation'
          component={'nav'}
          aria-labelledby='nested-list-subheader'
          sx={{ flex: '1 0 auto', mt: 2 }}
          dense
        >
          <ListItemButton
            href={`#hero`}
            onClick={() => {
              handleClose()
            }}
            sx={{
              backgroundColor: 'inherit',
              borderTop: 1,
              borderBottom: 1,
              borderColor: 'inherit',
              marginBottom: 2,
              color: 'inherit',
              '&:hover': {
                borderImage: RainbowColorValue,
                borderImageSlice: 1,
                animation: 'textAnimate 5s linear infinite reverse',
              },
              '&:hover *': {
                background: RainbowColorValue,
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                animation: 'textAnimate 5s linear infinite reverse',
                transform: 'translateX(10px)',
                '@keyframes textAnimate': {
                  from: {
                    filter: 'hue-rotate(0deg)',
                    backgroundPositionX: '0%',
                  },
                  to: {
                    filter: 'hue-rotate(360deg)',
                    backgroundPositionX: '100%',
                  },
                },
              },
            }}
          >
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              textTransform={'uppercase'}
              py={1}
              color={'inherit'}
            >
              Home
            </Typography>
          </ListItemButton>
          {nav.map((link) => (
            <ListItemButton
              component={RainbowLink}
              href={`#${link.to}`}
              onClick={() => {
                handleClose()
              }}
            >
              <ListItemText>
                <Typography
                  color={'inherit'}
                  variant='subtitle1'
                  fontWeight={'bold'}
                  textTransform={'uppercase'}
                >
                  {link.label}
                </Typography>
              </ListItemText>
            </ListItemButton>
          ))}
          <ListItemButton
            href={mintingUrl}
            target='_blank'
            rel='noreferrer'
            onClick={() => {
              handleClose()
            }}
            sx={{
              backgroundColor: 'inherit',
              borderTop: 1,
              borderBottom: 1,
              borderColor: 'inherit',
              marginTop: 2,
              color: 'inherit',
              '&:hover': {
                borderImage: RainbowColorValue,
                borderImageSlice: 1,
                animation: 'textAnimate 5s linear infinite reverse',
              },
              '&:hover *': {
                background: RainbowColorValue,
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                animation: 'textAnimate 5s linear infinite reverse',
                transform: 'translateX(10px)',
                '@keyframes textAnimate': {
                  from: {
                    filter: 'hue-rotate(0deg)',
                    backgroundPositionX: '0%',
                  },
                  to: {
                    filter: 'hue-rotate(360deg)',
                    backgroundPositionX: '100%',
                  },
                },
              },
            }}
          >
            <Typography
              variant='subtitle1'
              fontWeight={'bold'}
              textTransform={'uppercase'}
              py={1}
              color={'inherit'}
            >
              Start your journey
            </Typography>
          </ListItemButton>
        </List>
      </Box>
      <Stack
        direction='row'
        justifyContent={'space-between'}
        alignItems={'center'}
        py={2}
        px={5}
        sx={{ borderTop: 1, borderColor: 'inherit' }}
      >
        <NavIconButton
          aria-describedby={'dark-mode-button'}
          aria-label='dark-mode-button'
          size='large'
          sx={{
            ...(isLightMode && {
              color: 'secondary.main',
              borderColor: 'secondary.main',
              backgroundColor: 'primary.main',
              ':hover': {
                borderColor: 'transparent',
              },
            }),
          }}
          onClick={() => colorMode.toggleColorMode()}
        >
          <LightModeOutlined color='inherit' />
        </NavIconButton>
        {channelsLinks.map((link) => (
          <Link
            target='_blank'
            rel='noopener'
            href={link.to}
            color={'inherit'}
            underline='hover'
            variant='subtitle1'
            fontWeight={400}
          >
            {link.label}
          </Link>
        ))}
      </Stack>
    </>
  )
}

export default NavDrawer

interface IProps {
  handleClose: CallableFunction
}
