import { createTheme } from '@mui/material'
const defaultTheme = createTheme()

const theme = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'TT Interphases Pro',
    h1: {
      fontSize: '88px',
      fontWeight: 300,
    },
    h2: {
      fontSize: '64px',
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },
    },
    h3: {
      fontSize: '48px',
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    h4: {
      fontSize: '40px',
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    h5: {
      fontSize: '35px',
      fontWeight: 300,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'black',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('lg')]: {
            maxWidth: '1280px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          borderRadius: '30px',
          paddingLeft: '26px',
          paddingRight: '26px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .hidden-button': {
            position: 'absolute',
            backgroundColor: '#000',
            padding: 0,
            height: 'auto',
            maxHeight: 0,
            transition: 'max-height .5s ease',
            overflow: 'hidden',
            [defaultTheme.breakpoints.down('md')]: {
              maxHeight: '50%',
            },
          },
          '&:hover .hidden-button': {
            [defaultTheme.breakpoints.up('md')]: {
              maxHeight: '50%',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '100%',
          zIndex: -1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: `1px solid #000`,
          borderBottom: 0,
          '&.Mui-selected': {
            color: '#fff',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
          color: '#fff',
          textAlign: 'center',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid #000`,
          '&.Mui-selected': {
            color: '#fff',
          },
        },
      },
    },
  },
})

export default theme
