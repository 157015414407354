import { Box, ThemeProvider } from '@mui/material'

import DollSection from '../components/DollSection'
import { usePaletteMode } from 'hooks/RainbowSue/usePaletteMode'
import { createContext } from 'react'

export const ColorModeContext = createContext({ toggleColorMode: () => {} })

export default function DollPage() {
  const { theme, colorMode } = usePaletteMode('dark')

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            backgroundColor: 'black',
            minHeight: 'calc(100vh - 40px)',
            paddingTop: '40px',
          }}
        >
          <DollSection />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}
