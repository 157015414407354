import useIsMobile from 'hooks/useIsMobile'
import SueUtilitiesDesktop from './UtilitiesDesktop'
import SueUtilitiesMobile from './UtilitiesMobile'

const SueUtilities: React.FC = () => {
  const isMobile = useIsMobile()

  return isMobile ? <SueUtilitiesMobile /> : <SueUtilitiesDesktop />
}

export default SueUtilities
