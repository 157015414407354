import { Box, Grid, Typography, styled, useTheme } from '@mui/material'
import Marquee from 'react-fast-marquee'
import { motion } from 'framer-motion'

import { ReactComponent as FiveText } from 'assets/rainbowSue/555.svg'
import { ReactComponent as ShovelIcon } from 'assets/rainbowSue/shovel.svg'
import { ReactComponent as MoonStarIcon } from 'assets/rainbowSue/moon-star.svg'
import { ReactComponent as SunIcon } from 'assets/rainbowSue/sun.svg'

import {
  RainbowColorValue,
  DarkRainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import StartButton from 'components/RainbowSue/StartButton'
import { useTranslation } from 'react-i18next'
import { ScrollReveal } from './ScrollReveal'
import SectionHeader from './SectionHeader'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'

const Image = styled('img')({
  width: '180px',
  height: '180px',
  objectFit: 'cover',
  marginRight: '20px',
})

const images = new Array(24).fill(0)

const mintDetails = [
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintSupply',
    value: '555',
  },
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintDate',
    value: '07  SEPTEMBER  2023',
  },
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintPrice',
    value: '45 MATIC',
  },
]

const PixelSueDesktop = () => {
  const { t } = useTranslation()
  const isLightMode = useTheme().palette.mode === 'light'

  return (
    <Grid container overflow='hidden'>
      <Grid
        container
        xs={12}
        mt='145px'
        py='12px'
        justifyContent='space-evenly'
        borderColor={'primary.main'}
      >
        <SectionHeader title={t('rainbowsue.pixelSue.title')} mb='0px' />
      </Grid>
      <Grid component={ContainedDiv} container>
        <Grid my={10} container>
          <Marquee speed={200}>
            {images.map((_, index) => {
              return (
                <Image
                  key={index}
                  src={`/images/rainbowSue/pixel-sue/${index + 1}.png`}
                  alt={`pixel-sue-${index + 1}`}
                />
              )
            })}
          </Marquee>
        </Grid>

        {/* Mint Title */}
        <Grid
          container
          borderTop={1}
          borderBottom={1}
          borderColor={'primary.main'}
          justifyContent='space-between'
          alignItems='center'
          py={4}
        >
          <motion.div
            initial={{ opacity: 0, x: '-10%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Grid color={'primary.main'}>
              <FiveText width='216px' height='72px' />
              <Typography variant='subtitle1' color={'primary'} mt={1}>
                {t('rainbowsue.pixelSue.subtitle')}
              </Typography>
            </Grid>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: '10%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            style={{ width: '60%' }}
          >
            <Typography textAlign='right' color={'primary.main'}>
              {t('rainbowsue.pixelSue.description')}
            </Typography>
          </motion.div>
        </Grid>

        {/* Mint Details */}
        <Grid color='primary.main' py={2} container>
          <Grid
            flex={1}
            display='flex'
            flexDirection='column'
            py={3}
            pr={2}
            justifyContent='space-between'
          >
            <Grid container alignItems='center' gap={1}>
              <Typography variant='subtitle1'>
                {t('rainbowsue.pixelSue.mintDetails.title')}
              </Typography>
              <ShovelIcon />
            </Grid>
            <StartButton>Start your journey</StartButton>
            <Typography fontFamily='Clash Display' variant='h1'>
              {t('rainbowsue.pixelSue.mintDetails.description')}
            </Typography>
          </Grid>
          <Grid
            pl={10}
            sx={{ borderLeft: '1px solid', borderColor: 'primary.main' }}
          >
            <motion.div
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: { duration: 1, staggerChildren: 0.5 },
                },
              }}
              initial='hidden'
              whileInView='visible'
            >
              {mintDetails.map((detail, index) => (
                <motion.div
                  key={detail.label}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: {
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.5 },
                    },
                  }}
                >
                  <Box
                    pt={3}
                    pb={4}
                    sx={
                      index !== mintDetails.length - 1
                        ? {
                            borderBottom: '1px solid',
                            borderColor: 'primary.main',
                          }
                        : undefined
                    }
                  >
                    <Typography variant='subtitle1' textTransform='uppercase'>
                      {t(detail.label)}
                    </Typography>
                    <Typography
                      sx={{
                        background: isLightMode
                          ? DarkRainbowColorValue
                          : RainbowColorValue,
                        '-webkit-background-clip': 'text',
                        '-webkit-text-fill-color': 'transparent',
                      }}
                      fontFamily='Clash Display'
                      variant='h1'
                    >
                      {detail.value}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </motion.div>
          </Grid>
        </Grid>

        {/* Embark section */}
        <Grid container>
          <Grid
            container
            py={2}
            borderTop={1}
            borderBottom={1}
            borderColor='primary.main'
            color='primary.main'
          >
            <Typography variant='subtitle1' textTransform='uppercase'>
              {t('rainbowsue.pixelSue.embark.title')}
            </Typography>
          </Grid>
          <Grid
            py={2}
            container
            color='primary.main'
            gap={10}
            flexWrap='nowrap'
            alignItems='stretch'
          >
            <Box width='45%' flex={1}>
              <ScrollReveal>
                <Box height='50px'>
                  <MoonStarIcon width={45} height={45} />
                </Box>
                <Typography mt='20px'>
                  {t('rainbowsue.pixelSue.embark.moonText')}
                </Typography>
              </ScrollReveal>
            </Box>
            <Box sx={{ width: '1px', backgroundColor: 'primary.main' }} />
            <Box width='45%' flex={1}>
              <ScrollReveal delay={0.4}>
                <Box height='50px'>
                  <SunIcon />
                </Box>
                <Typography mt='20px'>
                  {t('rainbowsue.pixelSue.embark.sunText')}
                </Typography>
              </ScrollReveal>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PixelSueDesktop
