import { Box, Divider, Grid, Typography } from '@mui/material'
import sanitizeHtml from 'sanitize-html'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import useIsMobile from 'hooks/useIsMobile'
import SectionHeader from './SectionHeader'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import { ScrollReveal } from './ScrollReveal'

const artistImages = [
  {
    id: '1',
    image: '/images/rainbowSue/phannapast.png',
  },
  {
    id: '2',
    image: '/images/rainbowSue/phannapast2.png',
  },
  {
    id: '3',
    image: '/images/rainbowSue/phannapast3.png',
  },
  {
    id: '4',
    image: '/images/rainbowSue/phannapast4.png',
  },
  {
    id: '5',
    image: '/images/rainbowSue/phannapast5.png',
  },
]

const phannapastBio = `A Thai contemporary artist and designer, born and raised in Bangkok. After earning her degree in Fashion Design at Chulalongkorn University, Phannapast went on to collect 3 years of experience in commercial skills with a Thai fashion house as a creative director. \n
Phannapast started her own art and design studio as an independent artist soon after that. Her works are often inspired by her childhood stories and natural obsessions. She often gives hints and messages through her artworks in order to invite audiences to explore and see the world through her eyes. \n 
She has been working with various clients from both international and local ones. Her CV is peppered with artwork commissions from iconic local brands – The Peninsula Bangkok, Jim Thompson, Siam Piwat Group, King Power, etc. – as well as international heavyweights such as Gucci, Bang and Olufsen, Nescafé, Nike, Sulwhasoo, and Instagram.`

const ArtistSection = () => {
  const isMobile = useIsMobile()

  return isMobile ? (
    <Grid container width={'100%'}>
      <SectionHeader title={'the artist'} alignRight mb={isMobile && '16px'} />
      <Grid container width='100%'>
        <Grid item xs={2}>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              zIndex: 1,
              borderColor: 'primary.main',
              height: '420px',
              mr: '20px',
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className='rainbowsue__swiper'
          >
            {artistImages.map((content) => (
              <SwiperSlide key={`elements-mobile-${content.id}`}>
                <Box
                  my={1}
                  mx={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <img
                    src={content.image}
                    alt={content.image}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '400px',
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid item xs={2}>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              zIndex: 1,
              borderColor: 'primary.main',
              height: '420px',
              mr: '40px',
            }}
          />
        </Grid>
        <ScrollReveal>
          <Grid
            item
            xs={12}
            my={4}
            py={1}
            px={isMobile && 2}
            mx={isMobile && 2}
            borderTop={1}
            borderBottom={1}
            borderColor={'primary.main'}
          >
            <Typography
              variant='subtitle1'
              color='primary'
              textTransform='uppercase'
            >
              PHANNAPAST TAYCHAMAYTHAKOOL
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} mb={6} px={4}>
            <Typography color='primary' align='justify' fontSize='14px'>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(phannapastBio),
                }}
              />{' '}
            </Typography>
          </Grid>
        </ScrollReveal>
      </Grid>
    </Grid>
  ) : (
    <Grid item container sx={{ alignItems: 'center' }}>
      <SectionHeader title={'the artist'} />
      <Grid component={ContainedDiv} container>
        <Grid
          container
          lg={7}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent='center'
        >
          <ScrollReveal>
            <Box
              py={2}
              borderTop={1}
              borderBottom={1}
              borderColor={'primary.main'}
            >
              <Typography
                variant='subtitle1'
                color='primary'
                textTransform='uppercase'
              >
                PHANNAPAST TAYCHAMAYTHAKOOL
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography color='primary' align='justify'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(phannapastBio),
                  }}
                />{' '}
              </Typography>
            </Box>
          </ScrollReveal>
        </Grid>
        <Grid item md={1} />
        <Grid item lg={4} md={5} display={'flex'} flexDirection={'column'}>
          <Box>
            <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className='rainbowsue__swiper'
            >
              <Box px={2} py={2}>
                {artistImages.map((content) => (
                  <SwiperSlide key={content.id}>
                    <img width='100%' src={content.image} alt={content.image} />
                  </SwiperSlide>
                ))}
              </Box>
            </Swiper>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ArtistSection
