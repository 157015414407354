import { EthereumClient, w3mConnectors } from '@web3modal/ethereum'
import { publicProvider } from 'wagmi/providers/public'
import { infuraProvider } from 'wagmi/providers/infura'

import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, goerli, mainnet, polygon } from 'wagmi/chains'

interface ProvidersProps {
  children: React.ReactNode
}

const projectId = 'ff2e8a61206345a0257fa03a96d0ae49'

const supportedChains = [arbitrum, mainnet, polygon, goerli]

// preparation to migate to wagmi v1
const { chains, publicClient, webSocketPublicClient } = configureChains(
  supportedChains,
  [
    infuraProvider({ apiKey: 'ee686783a3f54a23846177276d90dbe2' }),
    publicProvider(),
  ],
)

const config = createConfig({
  autoConnect: true,
  publicClient,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  webSocketPublicClient,
})

const ethereumClient = new EthereumClient(config, chains)

const WagmiProvider: React.FC<ProvidersProps> = ({ children }) => (
  <>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    <WagmiConfig config={config}>{children}</WagmiConfig>
  </>
)
export default WagmiProvider
