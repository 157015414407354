import { Box, Grid, Typography, styled, useTheme } from '@mui/material'
import Marquee from 'react-fast-marquee'
import { motion } from 'framer-motion'

import { ReactComponent as FiveText } from 'assets/rainbowSue/555.svg'
import { ReactComponent as ShovelIcon } from 'assets/rainbowSue/shovel.svg'
import { ReactComponent as MoonStarIcon } from 'assets/rainbowSue/moon-star.svg'
import { ReactComponent as SunIcon } from 'assets/rainbowSue/sun.svg'
import { ReactComponent as TimelineIcon } from 'assets/rainbowSue/timeline.svg'

import {
  RainbowColorValue,
  DarkRainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import StartButton from 'components/RainbowSue/StartButton'
import { useTranslation } from 'react-i18next'
import { ScrollReveal } from './ScrollReveal'
import SectionHeader from './SectionHeader'

const Image = styled('img')({
  width: '120px',
  height: '120px',
  objectFit: 'cover',
})

const images = new Array(24).fill(0)

const mintDetails = [
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintSupply',
    value: '555',
  },
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintDate',
    value: '07  SEPTEMBER  2023',
  },
  {
    label: 'rainbowsue.pixelSue.mintDetails.mintPrice',
    value: '45 MATIC',
  },
]

const PixelSueMobile = () => {
  const { t } = useTranslation()
  const isLightMode = useTheme().palette.mode === 'light'

  return (
    <Grid container overflow='hidden'>
      <Grid container xs={12} mt='145px' py='12px' borderColor={'primary.main'}>
        <SectionHeader
          title={t('rainbowsue.pixelSue.title')}
          alignRight
          mb='0px'
        />
      </Grid>
      <Grid container px={2}></Grid>
      <Grid container my={6} px={2}>
        <Marquee speed={100}>
          {images.map((_, index) => {
            return (
              <Image
                key={index}
                src={`/images/rainbowSue/pixel-sue/${index + 1}.png`}
                alt={`pixel-sue-${index + 1}`}
              />
            )
          })}
        </Marquee>
      </Grid>

      {/* Mint Title */}
      <Grid container px={2}>
        <Grid
          container
          borderTop={1}
          borderBottom={1}
          borderColor={'primary.main'}
          flexDirection='column'
          py={4}
        >
          <motion.div
            initial={{ opacity: 0, x: '-10%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Grid
              container
              flexDirection='column'
              alignItems='stretch'
              color={'primary.main'}
              sx={{
                '& svg': {
                  animation: 'blink 1s infinite linear',
                },
                '@keyframes blink': {
                  from: {
                    fill: 'none',
                  },
                  to: {
                    fill: isLightMode ? '#FF4081' : '#fff',
                  },
                },
              }}
            >
              <FiveText width='100%' height={100} />
              <Typography
                variant='subtitle1'
                color={'primary'}
                textAlign='center'
                mt={3}
              >
                {t('rainbowsue.pixelSue.subtitleMobile')}
              </Typography>
            </Grid>
            <Box width='100%' height='1px' bgcolor='primary.main' my={2} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: '10%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography textAlign='center' color={'primary.main'}>
              {t('rainbowsue.pixelSue.description')}
            </Typography>
          </motion.div>
        </Grid>
      </Grid>

      {/* Mint Details */}
      <Grid color='primary.main' pt={2} px={2} container>
        <Grid
          container
          pb={2}
          borderBottom={1}
          borderColor='primary.main'
          alignItems='center'
          gap={1}
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center' gap={1}>
            <Typography variant='subtitle1'>
              {t('rainbowsue.pixelSue.mintDetails.title')}
            </Typography>
            <ShovelIcon />
          </Box>
          <StartButton>Start your journey</StartButton>
        </Grid>
        <Grid container>
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: { duration: 1, staggerChildren: 0.5 },
              },
            }}
            initial='hidden'
            whileInView='visible'
            style={{ width: '100%' }}
          >
            {mintDetails.map((detail, index) => (
              <motion.div
                key={detail.label}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                }}
              >
                <Box
                  pt={3}
                  pb={4}
                  textAlign='right'
                  sx={
                    index !== mintDetails.length - 1
                      ? {
                          borderBottom: '1px solid',
                          borderColor: 'primary.main',
                        }
                      : undefined
                  }
                >
                  <Grid
                    alignItems='flex-end'
                    display='flex'
                    flexDirection='column'
                  >
                    <Typography variant='subtitle1' textTransform='uppercase'>
                      {t(detail.label)}
                    </Typography>
                    <Typography
                      sx={{
                        background: isLightMode
                          ? DarkRainbowColorValue
                          : RainbowColorValue,
                        '-webkit-background-clip': 'text',
                        '-webkit-text-fill-color': 'transparent',
                      }}
                      fontFamily='Clash Display'
                      variant='h1'
                    >
                      {detail.value}
                    </Typography>
                  </Grid>
                </Box>
              </motion.div>
            ))}
          </motion.div>
        </Grid>
      </Grid>
      <Grid
        container
        py={2}
        borderTop={1}
        borderBottom={1}
        borderColor='primary.main'
        sx={{ overflowX: 'hidden' }}
      >
        <Marquee>
          <Typography
            color='primary.main'
            fontFamily='Clash Display'
            variant='h1'
            mr={1}
          >
            {t('rainbowsue.pixelSue.mintDetails.description')}
          </Typography>
        </Marquee>
      </Grid>

      {/* Embark section */}
      <Grid container mb={12}>
        <Grid
          py={2}
          px={2}
          mb={4}
          container
          color='primary.main'
          gap={2}
          flexDirection='column'
          alignItems='stretch'
        >
          <Box flex={1} pb={2} borderBottom={1} borderColor='primary.main'>
            <ScrollReveal>
              <TimelineIcon />
              <Typography mt={1}>
                {t('rainbowsue.pixelSue.embark.title')}
              </Typography>
            </ScrollReveal>
          </Box>
          <Box flex={1} pb={2} borderBottom={1} borderColor='primary.main'>
            <ScrollReveal>
              <MoonStarIcon width={41} height={41} />
              <Typography mt={1}>
                {t('rainbowsue.pixelSue.embark.moonText')}
              </Typography>
            </ScrollReveal>
          </Box>
          <Box flex={1}>
            <ScrollReveal delay={0.4}>
              <SunIcon width={45} height={45} />
              <Typography mt={1}>
                {t('rainbowsue.pixelSue.embark.sunText')}
              </Typography>
            </ScrollReveal>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PixelSueMobile
