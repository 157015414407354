import { Link, styled } from '@mui/material'

export const RainbowColorValue =
  '-webkit-linear-gradient( 45deg, #31EAE3 0%, #E1F664 20% , #FEB0FE 40%, #ABB3FC 60%, #5DF7A4 80%, #58C4F6 100%)'

export const DarkRainbowColorValue =
  '-webkit-linear-gradient( 45deg, #00A5A5 0%, #8D8600 20% , #DE47E0 40%, #717BEC 60%, #00913E 80%, #249CE4 100%)'

const RainbowLink = styled(Link)(({ theme }) => ({
  fontFamily: 'Helvetica',
  '&:hover': {
    cursor: 'pointer',
    background:
      theme.palette.mode === 'light'
        ? DarkRainbowColorValue
        : RainbowColorValue,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    animation: 'textAnimate 5s linear infinite reverse',
    transform: 'translateX(10px)',
    '@keyframes textAnimate': {
      from: {
        filter: 'hue-rotate(0deg)',
        backgroundPositionX: '0%',
      },
      to: {
        filter: 'hue-rotate(360deg)',
        backgroundPositionX: '100%',
      },
    },
  },
})) as typeof Link

export default RainbowLink
