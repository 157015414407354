import { useState, useEffect } from 'react'

export const useSectionObserver = (sections: string[]) => {
  const [section, setSection] = useState<string | null>('hero')

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSection(entry.target.id)
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.35,
    })

    const observerHero = new IntersectionObserver(handleIntersection, {
      threshold: 0.05,
    })

    let obHeroSection = document.getElementById('hero')
    obHeroSection && observerHero.observe(obHeroSection)

    sections.forEach((section) => {
      if (section !== 'hero') {
        let obSection = document.getElementById(section)
        obSection && observer.observe(obSection)
      }
    })

    return () => observer.disconnect()
  }, [section, sections])

  return { section }
}
