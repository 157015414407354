import Axios from 'axios'
import { WalletSignMessagePayload } from 'constants/types'

const ACCOUNT_ADD_EOA_ENDPOINT = `/api/v1/profile/addEOA`
const GENERATE_MESSAGE_ENDPOINT = `/api/v1/profile/generateMessage`

export const ProfileApi = {
  addEOA: async function (
    walletSignMessagePayload: WalletSignMessagePayload,
  ): Promise<boolean> {
    try {
      await Axios.post(ACCOUNT_ADD_EOA_ENDPOINT, walletSignMessagePayload)
      return true
    } catch (err: any) {
      console.log(err)

      // status >= 400 response messages
      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  generateMessage: async function (addr: string): Promise<string> {
    try {
      const endpoint = `${GENERATE_MESSAGE_ENDPOINT}?addr=${addr}`
      const response = await Axios.get(endpoint)

      const message = response.data.message
      return message
    } catch (err: any) {
      console.log(err)

      // status >= 400 response messages
      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
