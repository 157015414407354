import useIsMobile from 'hooks/useIsMobile'
import { useEffect, useState } from 'react'

const IMAGE_START_INDEX = 40

export const useScrollingEffect = () => {
  const [isEnded, setIsEnded] = useState(false)
  const isMobile = useIsMobile()

  //Event Listener must be added once to prevent glitchy animations

  //Functions in useEffect hook so that depedencies would not cause it to be called again
  useEffect(() => {
    const frameCount = 240 - IMAGE_START_INDEX
    const img = new Image()

    //Init function to set Canvas property
    const setCanvas = () => {
      const canvas = document.getElementById('heroCanvas') as HTMLCanvasElement
      if (canvas) {
        canvas.width = isMobile ? 607 : 1920
        canvas.height = isMobile ? 1080 : 1088
        const context = canvas.getContext('2d')
        if (context && img) {
          img.onload = function () {
            context.drawImage(img, 0, 0)
          }
        }
      }
    }

    //Function to update img src
    const updateImage = (index: number) => {
      if (img)
        img.src = `/images/rainbowSue/rs-hero-section/${
          isMobile ? 'mobile' : 'desktop'
        }/rs-hero-${index}.jpg`
    }

    //Helper function to preload images
    const preloadImages = () => {
      for (let i = IMAGE_START_INDEX; i < frameCount; i++) {
        const img = new Image()
        img.src = `/images/rainbowSue/rs-hero-section/${
          isMobile ? 'mobile' : 'desktop'
        }/rs-hero-${i}.jpg`
      }
    }

    //Main Scrolling function
    const scrollProgress = () => {
      let div = document.getElementById('hero')
      if (div) {
        const scrollTop = document.documentElement.scrollTop
        const maxScrollTop = div.offsetHeight - window.screen.height
        const scrollFraction = scrollTop / maxScrollTop

        const frameIndex = Math.min(
          frameCount - 1,
          Math.ceil(scrollFraction * frameCount + IMAGE_START_INDEX),
        )

        requestAnimationFrame(() =>
          updateImage(
            Math.min(frameCount, Math.max(frameIndex, IMAGE_START_INDEX)),
          ),
        )

        frameIndex >= frameCount - 1 ? setIsEnded(true) : setIsEnded(false)
      }
    }

    updateImage(IMAGE_START_INDEX)

    if (img !== null) {
      setCanvas()
      window.addEventListener('scroll', scrollProgress)
      preloadImages()
    }
    return () => {}
  }, [isMobile])
  return { isEnded }
}
