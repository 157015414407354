import { NavigateNext } from '@mui/icons-material'
import { Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import {
  DarkRainbowColorValue,
  RainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollReveal } from './ScrollReveal'
import SectionHeader from './SectionHeader'

export const CustomRoundedButtonBox = styled(Box)<{ size: number }>(
  ({ theme, size }) => ({
    height: `${size}px`,
    width: `${size}px`,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      theme.palette.mode === 'light'
        ? 'inset 0 0 0 2px #FF4081'
        : 'inset 0 0 0 2px #fff',
  }),
)

export const RoundedButton: React.FC<{
  index: number
  active: boolean
  setPage: Dispatch<SetStateAction<number>>
  size?: number
}> = (props: {
  index: number
  active: boolean
  setPage: Dispatch<SetStateAction<number>>
  size?: number
}) => {
  const { index, active, setPage, size = 40 } = props
  console.log('size: ', size)
  return (
    <CustomRoundedButtonBox
      onClick={() => setPage(index)}
      size={size}
      sx={{
        background: active ? 'url(/images/rainbowSue/Utitlies_disc_1.png)' : '',
        backgroundSize: 'contain',
        cursor: 'pointer',
        ...(active && { boxShadow: 'none !important' }),
      }}
    >
      <Typography
        variant='h4'
        color={active ? '#141414' : 'primary'}
        textTransform='uppercase'
      >
        {Number(index + 1) > 9 ? index + 1 : `0${index + 1}`}
      </Typography>
    </CustomRoundedButtonBox>
  )
}

const UtilityInfo: React.FC<{
  title: string
  content: string
}> = (props: { title: string; content: string }) => {
  const { title, content } = props

  return (
    <>
      <Box borderTop={1} borderBottom={1} borderColor={'primary.main'} mb={2}>
        <Typography
          variant='subtitle1'
          color='primary'
          textTransform='uppercase'
          py={1}
        >
          {title}
        </Typography>
      </Box>
      <Typography color='primary' textAlign='justify' pt={4}>
        {content}
      </Typography>
    </>
  )
}

const SueUtilitiesDesktop: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isLargeWindow = useMediaQuery(theme.breakpoints.up('lg'))

  const [utilityList, setUtilityList] = useState<
    {
      title: string
      content: string
    }[]
  >([])

  useEffect(() => {
    const data = Object.entries(
      t('rainbowsue.utilities.utilityInfos', { returnObjects: true }),
    ).map(([key, value]) => ({ title: value.title, content: value.content }))

    setUtilityList(data)
  }, [t])

  const [page, setPage] = useState<number>(0)
  const isLightMode = useTheme().palette.mode === 'light'

  return (
    <Box height={'100%'} display='flex' flexDirection='column'>
      <SectionHeader title={t('rainbowsue.utilities.sectionTitle')} />
      <ScrollReveal>
        <Box flexGrow={1} display='flex' alignItems='center' overflow='visible'>
          <Box component={ContainedDiv} display='flex'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              width={isLargeWindow ? '50%' : '100%'}
            >
              <Box>
                <Box display='flex' justifyContent='space-between' mb={4}>
                  {utilityList.map((utility, index) => (
                    <RoundedButton
                      index={index}
                      active={page === index}
                      setPage={setPage}
                      key={`sue-utility-${index}`}
                      size={48}
                    />
                  ))}
                </Box>
                {utilityList[page] && (
                  <UtilityInfo
                    title={utilityList[page].title}
                    content={utilityList[page].content}
                  />
                )}
              </Box>
              <Box
                width={'100%'}
                mt={6}
                borderTop={1}
                borderBottom={1}
                borderColor={'primary.main'}
              >
                <ContainedDiv sx={{ margin: 0 }}>
                  <Typography
                    variant='subtitle1'
                    color={'secondary'}
                    fontWeight={'bold'}
                    textTransform={'uppercase'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      background: isLightMode
                        ? DarkRainbowColorValue
                        : RainbowColorValue,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      cursor: 'pointer',
                      minHeight: '40px',
                      animation: 'textAnimate 5s linear infinite reverse',
                      '@keyframes textAnimate': {
                        from: {
                          filter: 'hue-rotate(0deg)',
                          backgroundPositionX: '0%',
                        },
                        to: {
                          filter: 'hue-rotate(360deg)',
                          backgroundPositionX: '100%',
                        },
                      },
                    }}
                    onClick={() =>
                      setPage(page + 1 >= utilityList.length ? 0 : page + 1)
                    }
                  >
                    {page < 4 && (
                      <>
                        {t('rainbowsue.utilities.nextTitle', {
                          sectionTitle:
                            utilityList[page + 1]?.title ||
                            utilityList[0]?.title,
                        })}
                        <NavigateNext sx={{ color: '#5DF7A4', fontSize: 40 }} />
                      </>
                    )}
                  </Typography>
                </ContainedDiv>
              </Box>
            </Box>

            {isLargeWindow && (
              <Box px={10} width='40%'>
                <Box
                  component='img'
                  src='/images/rainbowSue/Utilities_1.png'
                  width='100%'
                  sx={{
                    transform: 'scale(0.8)',
                    zIndex: 20,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ScrollReveal>
    </Box>
  )
}

export default SueUtilitiesDesktop
