import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'
import { aspectRatioWidthValue } from 'theme/rainbowsueTheme'
import { ScrollReveal } from './ScrollReveal'

const SueProtocol: React.FC<{
  index: number | string
  title: string
  content: string
}> = (props: { index: number | string; title: string; content: string }) => {
  const { index, title, content } = props

  return (
    <Grid item xs={4}>
      <Typography variant='h3' color='primary' fontWeight='bold' py={1}>
        {Number(index) > 9 ? index : `0${index}`}
      </Typography>
      <Box borderTop={1} borderBottom={1} borderColor={'primary.main'} mb={2}>
        <Typography
          variant='subtitle1'
          color='primary'
          py={1}
          textTransform='uppercase'
        >
          {title}
        </Typography>
      </Box>
      <Typography color='primary' py={2} textAlign='justify'>
        {content}
      </Typography>
    </Grid>
  )
}

const SuePassDesktop: React.FC = () => {
  const [isVideoHovered, setIsVideoHovered] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const suePassDesktopRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const [protocolList, setProtocolList] = useState<
    {
      title: string
      content: string
    }[]
  >([])

  useEffect(() => {
    if (!suePassDesktopRef) return
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef?.current?.play()
        }
      },
      { threshold: 0.8 },
    )

    if (suePassDesktopRef.current) {
      observer.observe(suePassDesktopRef.current)
    }
  }, [videoRef])

  useEffect(() => {
    const data = Object.entries(
      t('rainbowsue.suePass.protocolInfos', { returnObjects: true }),
    ).map(([key, value]) => ({ title: value.title, content: value.content }))

    setProtocolList(data)
  }, [t])

  const handleFullscreen = () => {
    if (videoRef.current && videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      ref={suePassDesktopRef}
    >
      <SectionHeader title={t('rainbowsue.suePass.stakingTitle')} mb='48px' />

      <Box
        py={4}
        pb={isVideoHovered ? '0px' : '37px'}
        onClick={() => videoRef?.current?.play()}
        maxWidth={aspectRatioWidthValue}
        mx={'auto'}
      >
        <video
          ref={videoRef}
          src='/images/rainbowSue/rainbowsue_d-fin.mp4'
          style={{
            objectFit: 'cover',
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          muted
          loop
          poster='/images/rainbowSue/suepass-poster.png'
          onMouseEnter={() => setIsVideoHovered(true)}
          onMouseLeave={() => setIsVideoHovered(false)}
          autoPlay
        ></video>

        {isVideoHovered && (
          <Box display='flex' justifyContent='end' width='100%'>
            <Button
              sx={{ bottom: '70px', right: '5px' }}
              onClick={handleFullscreen}
              onMouseEnter={() => setIsVideoHovered(true)}
            >
              <img
                style={{ height: '25px', width: '25px' }}
                src='/images/rainbowSue/expand-solid.svg'
                alt='expand'
              />
            </Button>
          </Box>
        )}
      </Box>
      <ScrollReveal>
        <ContainedDiv borderTop={1} borderColor={'primary.main'}>
          <Grid container spacing={2}>
            {protocolList.map((protocol, index) => (
              <SueProtocol
                title={protocol.title}
                content={protocol.content}
                index={index + 1}
                key={`sue-protocol-${index}`}
              />
            ))}
          </Grid>
        </ContainedDiv>
      </ScrollReveal>
    </Box>
  )
}

export default SuePassDesktop
