import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  createRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import { CustomRoundedButtonBox } from './UtilitiesDesktop'
import { ScrollReveal } from './ScrollReveal'

const RoundedButton: React.FC<{
  index: number
  active: boolean
  setActive: Function
  size?: number
}> = (props: {
  index: number
  active: boolean
  setActive: Function
  size?: number
}) => {
  const { index, active, setActive, size = 40 } = props
  return (
    <CustomRoundedButtonBox
      onClick={() => setActive()}
      size={size}
      sx={{
        background: active ? 'url(/images/rainbowSue/Utitlies_disc_1.png)' : '',
        backgroundSize: 'contain',
        ...(active && { boxShadow: 'none !important' }),
      }}
    >
      <Typography
        variant='h4'
        color={active ? '#141414' : 'primary'}
        textTransform='uppercase'
      >
        {Number(index + 1) > 9 ? index + 1 : `0${index + 1}`}
      </Typography>
    </CustomRoundedButtonBox>
  )
}

const UtilityInfo = forwardRef(
  (
    props: { title: string; content: string; setActive: Function },
    ref: any,
  ) => {
    const { title, content, setActive } = props

    useEffect(() => {
      if (!ref) return
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActive()
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.8 },
      )

      if (ref.current) {
        observer.observe(ref.current)
      }
    }, [ref, setActive])

    return (
      <Box ref={ref} minWidth='100%'>
        <FullWidthDiv
          borderTop={1}
          borderBottom={1}
          borderColor={'primary.main'}
        >
          <ContainedDiv>
            <Typography
              variant='subtitle1'
              color='primary'
              textTransform='uppercase'
              py={1}
            >
              {title}
            </Typography>
          </ContainedDiv>
        </FullWidthDiv>
        <FullWidthDiv>
          <ContainedDiv my={2}>
            <Typography color='primary' textAlign='justify'>
              {content}
            </Typography>
          </ContainedDiv>
        </FullWidthDiv>
      </Box>
    )
  },
)

const SueUtilitiesMobile: React.FC = () => {
  const { t } = useTranslation()

  const [utilityList, setUtilityList] = useState<
    {
      title: string
      content: string
    }[]
  >([])

  const [utilityRefGroup, setUtilityRefGroup] = useState<
    MutableRefObject<any>[]
  >([])

  const [activePage, setActivePage] = useState<number>(0)

  useEffect(() => {
    const data = Object.entries(
      t('rainbowsue.utilities.utilityInfos', { returnObjects: true }),
    ).map(([key, value]) => ({ title: value.title, content: value.content }))
    const refs = data.map(() => createRef())

    setUtilityList(data)
    setUtilityRefGroup(refs)
  }, [t])

  const handleScroll = (index: number) => {
    utilityRefGroup[index]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  return (
    <Box height='100%' display='flex' flexDirection='column' width='100%'>
      <SectionHeader
        title={t('rainbowsue.utilities.sectionTitle')}
        alignRight
        mb='0'
      />
      <ScrollReveal>
        <Box display='flex' justifyContent='center' py={2}>
          <Box
            component='img'
            src='/images/rainbowSue/Utilities_1.png'
            width='60%'
          />
        </Box>
        <FullWidthDiv
          py={2}
          borderTop={1}
          borderColor={'primary.main'}
          display='flex'
          justifyContent='space-between'
          px={2}
        >
          {utilityList.map((utility, index) => (
            <RoundedButton
              index={index}
              active={index === activePage}
              key={`sue-utility-button-${index}`}
              setActive={() => handleScroll(index)}
            />
          ))}
        </FullWidthDiv>
        <Box mb={1}>
          {utilityRefGroup[0] && (
            <Stack
              direction='row'
              spacing={2}
              sx={{
                overflowX: 'scroll',
                scrollSnapType: 'x mandatory',
              }}
            >
              {utilityList.map((utility, index) => (
                <UtilityInfo
                  title={utility.title}
                  content={utility.content}
                  setActive={() => setActivePage(index)}
                  key={`sue-utility-${index}`}
                  ref={utilityRefGroup[index]}
                />
              ))}
            </Stack>
          )}
        </Box>
      </ScrollReveal>
    </Box>
  )
}

export default SueUtilitiesMobile
