import { TInstagramMediaUrl } from 'constants/types'
import { useCallback, useState } from 'react'

import Api from '../api'

export const useGetInstagramMedia = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFetched, setIsFetched] = useState(false)

  const getInstagramMedia = useCallback(async (): Promise<
    TInstagramMediaUrl[] | null
  > => {
    try {
      setIsLoading(true)

      const result = await Api.instagram.getInstagramMedia()
      setIsLoading(false)
      setIsFetched(true)

      return result
    } catch (error: any) {
      setIsLoading(false)
      setIsFetched(true)
      return null
    }
  }, [])

  return { getInstagramMedia, isLoading, isFetched }
}
