import { useDispatch, useSelector } from 'react-redux'
import { setToastState } from 'state/toast/actions'
import { AppState } from 'state'
import { ToastType } from './reducer'

const useToast = () => {
  const dispatch = useDispatch()
  const toastState = useSelector<AppState, ToastType>(
    (state) => state.toast.toastState,
  )

  const showToast = (params: ToastType) => {
    dispatch(
      setToastState({
        ...toastState,
        ...params,
        open: true,
      }),
    )
  }

  const hideToast = () => {
    dispatch(
      setToastState({
        ...toastState,
        open: false,
      }),
    )
  }

  return { showToast, hideToast, toastState }
}

export default useToast
