import { Box, Grid, Typography, useTheme } from '@mui/material'
import RainbowLink, {
  DarkRainbowColorValue,
  RainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import 'swiper/css'
import 'swiper/css/navigation'
import SectionHeader from './SectionHeader'
import PixelSueDesktop from './PixelSueDesktop'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import { ScrollReveal } from './ScrollReveal'

type SummaryInformation = {
  key: string
  value: string
}

const AboutNft = () => {
  const { t } = useTranslation()

  const isLightMode = useTheme().palette.mode === 'light'

  const elements = Object.entries(
    t('rainbowsue.productElements', { returnObjects: true }),
  ).map(([key, value]) => ({
    id: value.id,
    name: value.name,
    description: value.description,
    image: value.image,
    summaryInformation: value.summaryInformation,
  }))

  const [activeElement, setActiveElement] = useState<{
    id: number
    name: string
    description: string
    image: string
    summaryInformation: SummaryInformation[]
  }>(elements[0])

  const onButtonClick = (id: string) => {
    const data = elements.find((element) => element.id === id)
    if (data) {
      setActiveElement(data)
    }
  }

  return (
    <Grid container>
      <SectionHeader title='about rainbowsue nft' />
      <Grid component={ContainedDiv} container>
        <Grid item xs={12}>
          <ScrollReveal>
            <Typography variant='body1' color={'primary'}>
              {t('rainbowsue.description')}
            </Typography>
          </ScrollReveal>
        </Grid>
      </Grid>
      <PixelSueDesktop />
      <Grid component={ContainedDiv} container>
        <Grid
          container
          xs={12}
          mt='145px'
          py='12px'
          justifyContent='space-evenly'
          borderTop={1}
          borderBottom={1}
          borderColor={'primary.main'}
        >
          {elements.map((element) => (
            <Grid
              item
              md={3}
              key={`element-${element.id}`}
              sx={{ textAlign: 'center', py: 1 }}
            >
              <RainbowLink
                underline='none'
                variant='subtitle1'
                textTransform='uppercase'
                textAlign='center'
                color={'primary'}
                onClick={() => onButtonClick(element.id)}
                sx={{
                  ...(element.id === activeElement?.id && {
                    background: isLightMode
                      ? DarkRainbowColorValue
                      : RainbowColorValue,
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
                    animation: 'textAnimate 5s linear infinite reverse',
                    '@keyframes textAnimate': {
                      from: {
                        filter: 'hue-rotate(0deg)',
                        backgroundPositionX: '0%',
                      },
                      to: {
                        filter: 'hue-rotate(360deg)',
                        backgroundPositionX: '100%',
                      },
                    },
                  }),
                }}
              >
                {element.name}
              </RainbowLink>
            </Grid>
          ))}
        </Grid>
        <ScrollReveal>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid
              container
              md={6}
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Box
                component={'img'}
                src={activeElement.image}
                alt={activeElement.name}
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid container md={6} flexDirection={'column'}>
              {activeElement.summaryInformation.map((information, index) => (
                <Box
                  key={`element-information-${information.key}`}
                  borderTop={index === 0 ? 1 : 0}
                  borderBottom={1}
                  borderColor={'primary.main'}
                  display='flex'
                  alignItems='center'
                  py={3}
                >
                  <Grid item md={12}>
                    <Typography
                      variant='subtitle2'
                      textTransform='uppercase'
                      color={'primary'}
                    >
                      {information.key}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      textTransform='uppercase'
                      color={'primary'}
                    >
                      {information.value}
                    </Typography>
                  </Grid>
                </Box>
              ))}
            </Grid>
            <ScrollReveal delay={0.5}>
              <Grid item md={12} display={'flex'} flexDirection={'column'}>
                <Box
                  py='12px'
                  mb={4}
                  borderTop={1}
                  borderBottom={1}
                  borderColor={'primary.main'}
                >
                  <Typography
                    variant='subtitle1'
                    textTransform='uppercase'
                    color={'primary'}
                  >
                    MEET the {activeElement.name}
                  </Typography>
                </Box>

                <Box height='200px'>
                  <Typography color={'primary'} align='justify'>
                    {activeElement.description}
                  </Typography>
                </Box>
              </Grid>
            </ScrollReveal>
          </Grid>
        </ScrollReveal>
      </Grid>
    </Grid>
  )
}

export default AboutNft
