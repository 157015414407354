export const navigations: INavigations[] = [
  {
    to: 'aboutNft',
    label: 'About RainbowSue',
  },
  {
    to: 'artist',
    label: 'The Artist',
  },
  {
    to: 'roadmap',
    label: 'Roadmap',
  },
  {
    to: 'suePass',
    label: 'Sue Pass',
  },
  {
    to: 'utilities',
    label: 'Utilities',
  },
  {
    to: 'faq',
    label: 'FAQ',
  },
  {
    to: 'partnership',
    label: 'Partnerships',
  },
  {
    to: 'instagram',
    label: 'Feed',
  },
]

export const companyLinks: ICompany[] = [
  {
    to: 'https://labelled.io/',
    label: 'Labelled.io',
  },
  {
    to: [
      '/pdf/rainbowSue/Introducing_RainbowSue_Celebrating_Inclusivity,_Freedom,_Beauty.pdf',
      '/pdf/rainbowSue/Introducing_RainbowSue_Celebrating_Inclusivity_Freedom_Beauty_and_Love_Dark.pdf',
    ],
    label: 'Litepaper',
  },
]

export const channelsLinks: INavigations[] = [
  {
    to: 'https://twitter.com/RainbowSueNFT',
    label: 'Twitter',
  },
  {
    to: 'https://www.instagram.com/rainbowsue.art/',
    label: 'Instagram',
  },
  {
    to: 'http://discord.gg/rainbowsuenft',
    label: 'Discord',
  },
]

interface INavigations {
  to: string
  label: string
}

interface ICompany extends Omit<INavigations, 'to'> {
  to: string | string[]
}
