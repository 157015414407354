import useIsMobile from 'hooks/useIsMobile'
import SuePassDesktop from './SuePassDesktop'
import SuePassMobile from './SuePassMobile'

const SuePass: React.FC = () => {
  const isMobile = useIsMobile()

  return isMobile ? <SuePassMobile /> : <SuePassDesktop />
}

export default SuePass
