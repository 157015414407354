import Axios from 'axios'
import {
  TCampaignCreateBody,
  TCampaignGetBody,
  TCampaignTicketCreateBody,
  TCampaignTicketGetBody,
  TCampaignUpdateBody,
  TClaimPhygitalBody,
} from 'constants/campaign'
import { Address } from 'wagmi'

const CAMPAIGN_ENDPOINT = '/api/v1/campaign'

export const CampaignApi = {
  createCampaign: async function (data: TCampaignCreateBody) {
    try {
      await Axios.post(CAMPAIGN_ENDPOINT, data)

      return
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  updateCampaign: async function (
    campaignCode: string,
    data: TCampaignUpdateBody,
  ) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/${campaignCode}`

      await Axios.put(endpoint, data)

      return
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  claimTicket: async function (
    referalCode: string,
    userDetails: { username?: string; walletAddress?: string | null },
  ) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/claim`

      await Axios.post(endpoint, userDetails)

      return
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  createBatchTicket: async function (
    campaignCode: string,
    tickets: TCampaignTicketCreateBody[],
  ) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/${campaignCode}/ticket`

      await Axios.post(endpoint, { tickets })

      return
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  isEligible: async function (
    campaignCode: string,
    userDetails: { username?: string; walletAddress?: string | null },
  ) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/${campaignCode}/eligible`

      const result = await Axios.get(endpoint, {
        params: userDetails,
      })

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  merkleProof: async function (campaignCode: string, address?: Address) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/${campaignCode}/merkleProof`

      const result = await Axios.get(endpoint, {
        params: { address },
      })

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getCampaigns: async function (filter: TCampaignGetBody = {}) {
    try {
      const result = await Axios.get(CAMPAIGN_ENDPOINT, {
        params: filter,
      })

      return result.data.campaigns
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getCampaign: async function (campaignCode: string) {
    try {
      const result = await Axios.get(`${CAMPAIGN_ENDPOINT}/${campaignCode}`)

      return result.data.campaign
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getTickets: async function (
    campaignId: string,
    filter: TCampaignTicketGetBody = {},
  ) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/${campaignId}/ticket`
      const result = await Axios.get(endpoint, {
        params: filter,
      })

      return result.data.tickets
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  claimPhygital: async function (body: TClaimPhygitalBody) {
    try {
      const endpoint = `${CAMPAIGN_ENDPOINT}/claim/${body.projectCode}`

      const result = await Axios.post(endpoint, body)

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
