import Axios from 'axios'

const LANDING_ENDPOINT = '/api/v1/landing/instagram'

export const InstagramAPI = {
  getInstagramMedia: async function () {
    try {
      const result = await Axios.get(`${LANDING_ENDPOINT}/rainbowsue`)

      return result.data
    } catch (error: any) {
      console.error(error)

      const message = error?.response?.data?.message
      if (message) throw Error(message)
      throw Error(error.message)
    }
  },
}
