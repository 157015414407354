import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import SectionHeader from './SectionHeader'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import { useParams } from 'react-router-dom'

type SummaryInformation = {
  key: string
  value: string
}

type ElementData = {
  id: string
  name: string
  fileUrl: string
  summaryInformation: SummaryInformation[]
}

const DollSection = () => {
  const { id } = useParams()
  const theme = useTheme()
  const { t } = useTranslation()

  const elements = Object.entries(
    t('rainbowsue.dollElements', { returnObjects: true }),
  ).map(([key, value]) => ({
    id: value.id,
    name: value.name,
    fileUrl: value.file,
    summaryInformation: value.summaryInformation,
  })) as ElementData[]

  const activeElement = elements.find((element) => element.id === id)

  return (
    <Grid container pb='80px'>
      {activeElement ? (
        <>
          <SectionHeader title={`RainbowSue#????`} mb='40px' />
          <Grid component={ContainedDiv} container>
            <Grid
              container
              sx={{
                alignItems: 'center',
                flexWrap: 'nowrap',
                gap: '40px',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap',
                },
              }}
            >
              <Grid
                container
                md={6}
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <video
                  style={{
                    width: '100%',
                    maxHeight: '70vh',
                    backgroundColor: 'black',
                  }}
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={activeElement.fileUrl} type='video/mp4' />
                </video>
              </Grid>
              <Grid container md={6} flexDirection={'column'}>
                {activeElement.summaryInformation.map((information, index) => (
                  <Box
                    key={`element-information-${information.key}`}
                    borderTop={index === 0 ? 1 : 0}
                    borderBottom={1}
                    borderColor={'primary.main'}
                    display='flex'
                    alignItems='center'
                    py={3}
                  >
                    <Grid item md={12}>
                      <Typography
                        variant='subtitle2'
                        textTransform='uppercase'
                        color={'primary'}
                      >
                        {information.key}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        textTransform='uppercase'
                        color={'primary'}
                      >
                        {information.value}
                      </Typography>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid component={ContainedDiv} container mt='150px'>
          <Box alignItems='center' justifyContent='center' width='100%'>
            <Typography
              variant='h1'
              color='primary'
              textAlign='center'
              mb='20px'
            >
              Doll not found
            </Typography>
            <Typography variant='h4' color='primary' textAlign='center'>
              The doll with this ID does not exist
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default DollSection
