import { PaletteMode, createTheme, useMediaQuery } from '@mui/material'
import { useMemo, useState } from 'react'
import rainbowsueDesktopTheme from 'theme/rainbowsueTheme'

export const usePaletteMode = (defaultMode?: string) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<PaletteMode>(
    defaultMode || prefersDarkMode ? 'dark' : 'light',
  )
  const theme = useMemo(() => createTheme(rainbowsueDesktopTheme(mode)), [mode])

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  return { mode, theme, colorMode }
}
