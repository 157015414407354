import React from 'react'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import store from './state'
import Toast from 'components/Toast'
import i18n from 'i18n'
import { ThemeProvider } from '@mui/material'
import theme from 'theme'
import WagmiProvider from 'components/WagmiProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'

interface ProvidersProps {
  children: React.ReactNode
}

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <WagmiProvider>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <Toast />
            {children}
          </Provider>
        </I18nextProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </WagmiProvider>
)
export default Providers
