import Axios from 'axios'
import {
  TCreateOrderBody,
  TStripePaymentIntentBody,
  TUpdatePaymentBody,
} from 'constants/store'

const STORE_ENDPOINT = '/api/v1/store'

export const StoreApi = {
  createStripePaymentIntent: async function (data: TStripePaymentIntentBody) {
    try {
      const endpoint = `${STORE_ENDPOINT}/payment/stripe/intent`

      const result = await Axios.post(endpoint, data)

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  createOrder: async function (data: TCreateOrderBody) {
    try {
      const endpoint = `${STORE_ENDPOINT}/order`

      const result = await Axios.post(endpoint, data)

      return result.data.order
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  calculate: async function (data: TCreateOrderBody) {
    try {
      const endpoint = `${STORE_ENDPOINT}/order/calculate`

      const result = await Axios.post(endpoint, data)

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  updatePayment: async function (orderId: string, data: TUpdatePaymentBody) {
    try {
      const endpoint = `${STORE_ENDPOINT}/order/${orderId}/updatePayment`

      const result = await Axios.put(endpoint, data)

      return result.data
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
