import { PaletteMode, ThemeOptions, createTheme } from '@mui/material'
declare module '@mui/material/Link' {
  interface LinkPropsVariantOverrides {
    active: true
  }
}
const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
})

export const rainbowsueDesktopTheme = (mode: PaletteMode) =>
  ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: '#FF4081',
            },
            secondary: {
              main: '#FDFCFB',
            },
            text: {
              primary: '#FDFCFB',
              secondary: '#FF4081',
            },
            background: {
              default: '#FDFCFB',
              paper: '#FDFCFB',
            },
          }
        : {
            primary: {
              main: '#FFFFFF',
            },
            secondary: { main: '#141414' },
            text: {
              primary: '#FFFFFF',
              secondary: '#141414',
            },
            background: {
              default: '#141414',
              paper: '#141414',
            },
          }),
    },
    typography: {
      h1: {
        fontSize: '36px',
        fontWeight: 700,
        fontFamily: 'Clash Display',
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '28px',
        },
      },
      h2: {
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '12px',
        },
      },
      h3: {
        fontFamily: 'Clash Display',
        fontSize: '22px',
        fontWeight: 700,
      },
      h4: {
        fontFamily: 'Clash Display',
        fontSize: '22px',
        fontWeight: 700,
      },
      subtitle1: {
        fontFamily: 'Helvetica',
        fontSize: '16px',
        fontWeight: 700,
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
      body1: {
        fontFamily: 'Helvetica',
        fontSize: '16px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            whiteSpace: 'pre-line',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: 'black',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputProps: {
            disableUnderline: true,
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.up('lg')]: {
              maxWidth: '1280px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '0',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          style: {
            borderRadius: '30px',
            paddingLeft: '26px',
            paddingRight: '26px',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '& .hidden-button': {
              position: 'absolute',
              backgroundColor: '#000',
              padding: 0,
              height: 'auto',
              maxHeight: 0,
              transition: 'max-height .5s ease',
              overflow: 'hidden',
              [defaultTheme.breakpoints.down('md')]: {
                maxHeight: '50%',
              },
            },
            '&:hover .hidden-button': {
              [defaultTheme.breakpoints.up('md')]: {
                maxHeight: '50%',
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '100%',
            zIndex: -1,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            border: `1px solid #000`,
            borderBottom: 0,
            '&.Mui-selected': {
              color: '#fff',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: '#000',
            color: '#fff',
            textAlign: 'center',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: `1px solid #000`,
            '&.Mui-selected': {
              color: '#fff',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  } as ThemeOptions)

export const aspectRatioWidthValue = '1980px'

export default rainbowsueDesktopTheme
