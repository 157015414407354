import Axios from 'axios'
import {
  TNfcCreateRequestBody,
  TNfcGetRequestQuery,
  TNfcUpdateRequestBody,
} from 'constants/nfc'

const NFC_ENDPOINT = '/api/v1/nfc'

export const NfcApi = {
  createNfc: async function (data: TNfcCreateRequestBody) {
    try {
      await Axios.post(NFC_ENDPOINT, data)
      return
    } catch (err: any) {
      console.log(err)

      // status >= 400 response messages
      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  updateNfc: async function (
    chipPublicKeyHash: string,
    projectId: string,
    data: TNfcUpdateRequestBody,
  ) {
    try {
      const endpoint = `${NFC_ENDPOINT}/${chipPublicKeyHash}/${projectId}`
      await Axios.put(endpoint, data)
      return
    } catch (err: any) {
      console.log(err)

      // status >= 400 response messages
      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getNfcGroup: async function (queryInfo: TNfcGetRequestQuery) {
    try {
      const result: any = await Axios.get(NFC_ENDPOINT, {
        params: queryInfo,
      })

      return result.data.nfcs
    } catch (err: any) {
      console.log(err)

      // status >= 400 response messages
      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
