import useIsMobile from 'hooks/useIsMobile'
import PartnershipMobile from './PartnershipMobile'
import PartnershipDesktop from './PartnershipDesktop'

const Partnership = () => {
  const isMobile = useIsMobile()

  return isMobile ? <PartnershipMobile /> : <PartnershipDesktop />
}

export default Partnership
