import { configureStore } from '@reduxjs/toolkit'
import accountReducer from './account/reducer'
import toastReducer from './toast/reducer'
import ecwidReducer from './ecwid/reducer'
import { save, load } from 'redux-localstorage-simple'

const PERSISTED_KEYS: string[] = ['account', 'ecwid']

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    account: accountReducer.reducer,
    toast: toastReducer,
    ecwid: ecwidReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS })),
  preloadedState: load({ states: PERSISTED_KEYS }),
})

// Infer the `AppState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
