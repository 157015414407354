import { Box, Grid, Typography } from '@mui/material'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import useIsMobile from 'hooks/useIsMobile'

const SectionHeader: React.FC<{
  title: string
  mb?: string
  alignRight?: boolean
  withButton?: boolean
  disableBorder?: boolean
}> = ({ title, mb, alignRight, withButton, disableBorder }) => {
  const isMobile = useIsMobile()

  return (
    <Grid item xs={12} mb={mb || (isMobile ? '88px' : '145px')}>
      {withButton && <Box py={'42px'}></Box>}
      <FullWidthDiv
        py='26px'
        sx={{
          ...(!disableBorder && {
            borderTop: 1,
            borderBottom: 1,
            borderColor: 'primary.main',
          }),
        }}
      >
        <ContainedDiv
          sx={{ ...(isMobile && alignRight && { justifyContent: 'end' }) }}
        >
          <Typography
            variant='h1'
            color={'primary'}
            textTransform={'uppercase'}
          >
            {title}
          </Typography>
        </ContainedDiv>
      </FullWidthDiv>
    </Grid>
  )
}

export default SectionHeader
