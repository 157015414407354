import { ReactElement } from 'react'
import { Fade, Modal, Backdrop, Grid } from '@mui/material'

type ModalRainbowsueProps = {
  open: boolean
  handleClose: () => void
  children: ReactElement
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  p: 3,
}

const ModalRainbowsue: React.FC<ModalRainbowsueProps> = ({
  open,
  handleClose,
  children,
}) => {
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{ zIndex: 10000 }}
    >
      <Grid
        sx={{
          ...style,
          overflowY: 'scroll',
        }}
      >
        <Fade in={open}>{children}</Fade>
      </Grid>
    </Modal>
  )
}

export default ModalRainbowsue
