import { ReactNode } from 'react'

import { styled, Box } from '@mui/material'
import useIsMobile from 'hooks/useIsMobile'

export const SnapScrollDiv = ({ children, id, className }: IProps) => {
  return (
    <Box
      id={id}
      className={className}
      sx={{
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 12,
        backgroundColor: '#141414',
        scrollSnapAlign: 'start',
        minHeight: '100vh',
        height: '100%',
        maxWidth: '1980px !important',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  )
}

export const DivWrapper = ({ children, id }: IProps) => {
  const isMobile = useIsMobile()
  return (
    <Box
      id={id}
      data-scroll-section
      sx={{
        backgroundColor: 'background.paper',
        minHeight: isMobile ? '-webkit-fill-available' : '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        ...(isMobile ? { pt: '82px' } : { py: '32px' }),
      }}
    >
      {children}
    </Box>
    // <Box
    //   id={id}
    //   data-scroll-section
    //   sx={{
    //     backgroundColor: '#141414',
    //     minHeight: isMobile ? '-webkit-fill-available' : '100vh',
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     width: '100%',
    //     alignItems: 'center',
    //     ...(isMobile ? { pt: '82px' } : { py: '32px' }),
    //   }}
    // >
    //   <Box
    //     sx={{
    //       paddingLeft: 4,
    //       paddingRight: 4,
    //       margin: 'auto',
    //       width: '100vw',
    //       ...(isMobile && { pb: '48px' }),
    //     }}
    //   >
    //     {children}
    //   </Box>
    // </Box>
  )
}

export const ScrollWrapper = ({ children, id }: IProps) => {
  const isMobile = useIsMobile()
  return (
    <Box
      id={id}
      data-scroll-section
      sx={{
        height: '100%',
        width: '100%',

        ...(isMobile ? { p: 0 } : { pt: 12, pb: 4 }),
      }}
    >
      {children}
    </Box>
  )
}

export const FullWidthDiv = styled(Box)((props) => ({
  maxWidth: '100vw',
  width: 'auto',
  alignItems: 'center',
  [props.theme.breakpoints.down('sm')]: {
    marginLeft: 16,
    marginRight: 16,
  },
}))

export const ContainedDiv = styled(Box)((props) => ({
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  maxWidth: '1640px !important',
  width: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  [props.theme.breakpoints.down('xl')]: {
    paddingLeft: '7.5%',
    paddingRight: '7.5%',
  },
  [props.theme.breakpoints.down('sm')]: {
    paddingLeft: '4.5%',
    paddingRight: '4.5%',
  },
}))

interface IProps {
  children: ReactNode
  id?: string
  className?: string
}
