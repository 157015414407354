import { useCallback, useRef, useState, createContext } from 'react'
import { Box, ThemeProvider } from '@mui/material'

import { ScrollWrapper } from 'components/RainbowSue/SnapScrollDiv'
import Header from 'components/RainbowSue/Header'
import SuePass from './components/SuePass'
import SueUtilities from './components/Utilities'
import AboutNft from './components/AboutNft'
import ArtistSection from './components/ArtistSection'
import FAQ from './components/FAQ'
import Instagram from './components/Instagram'
import HeroSection from './components/HeroSection'
import Footer from './components/Footer'
import Partnership from './components/Partnership'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Roadmap from './components/Roadmap'
import { usePaletteMode } from 'hooks/RainbowSue/usePaletteMode'

const sectionId = [
  'hero',
  'aboutNft',
  'artist',
  'suePass',
  'utilities',
  'faq',
  'partnership',
  'instagram',
  'footer',
  'roadmap',
]

export const ColorModeContext = createContext({ toggleColorMode: () => {} })

const RainbowSue = () => {
  const [shouldShowHeader, setShouldShowHeader] = useState(false)
  const containerRef = useRef(null)

  const showHeader = useCallback(() => {
    setShouldShowHeader(true)
  }, [setShouldShowHeader])

  const { theme, colorMode } = usePaletteMode()

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {shouldShowHeader && <Header sections={sectionId} />}
        <LocomotiveScrollProvider
          options={{
            smooth: false,
          }}
          watch={[]}
          containerRef={containerRef}
        >
          <Box
            component={'main'}
            data-scroll-container
            ref={containerRef}
            sx={{ backgroundColor: 'background.paper' }}
          >
            <HeroSection
              id={sectionId[0]}
              data-scroll-section
              showHeader={showHeader}
            />
            <ScrollWrapper id={sectionId[1]}>
              <AboutNft />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[2]}>
              <ArtistSection />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[9]}>
              <Roadmap />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[3]}>
              <SuePass />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[4]}>
              <SueUtilities />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[5]}>
              <FAQ />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[6]}>
              <Partnership />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[7]}>
              <Instagram />
            </ScrollWrapper>
            <ScrollWrapper id={sectionId[8]}>
              <Footer />
            </ScrollWrapper>
          </Box>
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default RainbowSue
