import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { TCartItem, TProductItem } from 'constants/ecwid'
import { fetchMultipleProducts, fetchOneProduct } from './fetch'
import { convertProductsArrayToObject } from './utils'

export enum EProductErrorCode {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND', // exact same error code as Ecwid
  API_CALL_FAILED = 'API_CALL_FAILED',

  // Ecwid public key will still allow you to receive product information
  // when product is disabled, but the product object will not have any meaningful
  // information
  PRODUCT_DISABLED = 'PRODUCT_DISABLED',
}

export interface EcwidState {
  products: TProductItem[]
  productDetails: Record<string | number, TProductItem>
  productErrors: Record<string | number, EProductErrorCode>

  isFetched: boolean
  isLoading: boolean

  // NOTE: cart is being used as a Map than an Array
  // for the sake of using it as a Counter. When submitting the cart
  // to the create order API, the cart must still be changed to an array
  cart: Record<string | number, number>
}

export const initialState: EcwidState = {
  products: [],
  productDetails: {},
  productErrors: {},
  cart: {},
  isFetched: false,
  isLoading: false,
}

export const ecwidSlice = createSlice({
  name: 'ecwid',
  initialState,
  reducers: {
    setEcwidProducts: (state: EcwidState, action) => {
      state.products = action.payload
    },
    setEcwidProductDetails: (state: EcwidState, action) => {
      state.productDetails = action.payload
    },
    addOneEcwidProductDetail: (state: EcwidState, action) => {
      const productId = action.payload.id
      state.productDetails[productId] = action.payload
    },
    addOneProductError: (state: EcwidState, action) => {
      const productId = action.payload.id
      const EproductErrorCode = action.payload.errorCode
      state.productErrors[productId] = EproductErrorCode
    },
    addToCart: (state: EcwidState, action) => {
      const key = action.payload.id
      state.cart[key] = (state.cart[key] || 0) + action.payload.quantity
    },
    removeFromCart: (state: EcwidState, action) => {
      const key = action.payload.id
      if ((state.cart[key] || 0) - action.payload.quantity < 0)
        state.cart[key] = 0
      state.cart[key] -= action.payload.quantity
    },
    emptyCart: (state: EcwidState) => {
      state.cart = {}
    },
    setIsFetched: (state: EcwidState, action) => {
      state.isFetched = action.payload
    },
    setIsLoading: (state: EcwidState, action) => {
      state.isLoading = action.payload
    },
  },
})

const {
  setEcwidProducts,
  setEcwidProductDetails,
  addOneEcwidProductDetail,
  addOneProductError,
  addToCart,
  setIsFetched,
  setIsLoading,
  removeFromCart,
  emptyCart,
} = ecwidSlice.actions

export const getCurrentEcwidProducts = async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const products = (await fetchMultipleProducts({ enabled: true })).items
    const productDetails = convertProductsArrayToObject(products)
    dispatch(setIsLoading(false))
    dispatch(setIsFetched(true))
    dispatch(setEcwidProducts(products))
    dispatch(setEcwidProductDetails(productDetails))
  } catch (e) {
    dispatch(setIsLoading(false))
    dispatch(setIsFetched(false))
    dispatch(setEcwidProducts([]))
  }
}

export const getEcwidProductById = async (
  dispatch: Dispatch,
  productId: string | number,
) => {
  try {
    const product = await fetchOneProduct(productId)

    setIsLoading(false)
    if (!product.enabled)
      dispatch(
        addOneProductError({
          id: productId,
          errorCode: EProductErrorCode.PRODUCT_DISABLED,
        }),
      )
    else dispatch(addOneEcwidProductDetail(product))
  } catch (err: any) {
    console.log(err)
    if (err.response.data.errorCode === EProductErrorCode.PRODUCT_NOT_FOUND)
      dispatch(
        addOneProductError({
          id: productId,
          errorCode: EProductErrorCode.PRODUCT_NOT_FOUND,
        }),
      )
    else
      dispatch(
        addOneProductError({
          id: productId,
          errorCode: EProductErrorCode.API_CALL_FAILED,
        }),
      )
    dispatch(setIsLoading(false))
  }
}

export const addEcwidItemToCart = (dispatch: Dispatch, item: TCartItem) => {
  dispatch(addToCart(item))
}

export const removeEcwidItemFromCart = (
  dispatch: Dispatch,
  item: TCartItem,
) => {
  dispatch(removeFromCart(item))
}

export const emptyEcwidCart = (dispatch: Dispatch) => {
  dispatch(emptyCart())
}

export default ecwidSlice
