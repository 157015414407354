import { useState } from 'react'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SectionHeader from './SectionHeader'
import { ContainedDiv } from 'components/RainbowSue/SnapScrollDiv'
import {
  DarkRainbowColorValue,
  RainbowColorValue,
} from 'components/RainbowSue/RainbowLink'
import { ScrollReveal } from './ScrollReveal'
// import Marquee from 'react-fast-marquee'

const rainbowStyleText = (isLightMode: boolean) => ({
  cursor: 'pointer',
  background: isLightMode ? DarkRainbowColorValue : RainbowColorValue,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  animation: 'textAnimate 5s linear infinite reverse',
  '@keyframes textAnimate': {
    from: {
      filter: 'hue-rotate(0deg)',
      backgroundPositionX: '0%',
    },
    to: {
      filter: 'hue-rotate(360deg)',
      backgroundPositionX: '100%',
    },
  },
})

const PartnershipDesktop = () => {
  const [activePartnership, setActivePartnership] = useState<{
    id: number
    name: string
    image: string
  } | null>(null)

  const { t } = useTranslation()

  const partnerships = Object.entries(
    t('rainbowsue.partnership', { returnObjects: true }),
  ).map(([key, value]) => ({
    id: value.id,
    name: value.name,
    occupation: value.occupation,
    image: value.image,
  }))

  const onButtonClick = (id: string) => {
    const data = partnerships.find((element) => element.id === id)
    if (data) {
      setActivePartnership(data)
    }
  }

  const isLightMode = useTheme().palette.mode === 'light'

  return (
    <ScrollReveal>
      <Grid container>
        <SectionHeader title='partnerships' />
        <Box
          component={ContainedDiv}
          flexDirection={'column'}
          width={'100%'}
          height={'450px'}
          justifyContent={'space-between'}
        >
          {activePartnership !== null && (
            <Box
              mx={6}
              pt={2}
              sx={{
                paddingRight: '24px',
                margin: 0,
                position: 'relative',
                top: '450px',
                paddingTop: 0,
                marginTop: '-450px',
                height: '450px',
                maxWidth: '450px',
                zIndex: 100,
                backgroundColor: 'background.paper',
              }}
            >
              <img
                src={activePartnership.image}
                alt={activePartnership.name}
                style={{
                  objectFit: 'cover',
                  aspectRatio: 1,
                }}
                height={'100%'}
              />
            </Box>
          )}

          {partnerships.map((partnership, index) => {
            const activeTextStyle =
              activePartnership?.id === partnership.id
                ? rainbowStyleText(isLightMode)
                : null

            return (
              <Grid
                container
                component={Button}
                sx={{
                  alignItems: 'stretch',
                  borderRadius: '0 !important',
                  height: '-webkit-fill-available',
                }}
                borderTop={index === 0 ? 1 : 0}
                borderBottom={1}
                width='100%'
                py={1}
                key={`partnership-${partnership.id}`}
                onMouseEnter={() => onButtonClick(partnership.id)}
              >
                <Grid item md={5} xl={5} borderColor={'primary.main'} />
                <Grid item md={3} xl={3} borderColor={'primary.main'}>
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    fontWeight={'bold'}
                    textTransform='uppercase'
                    textAlign='left'
                    sx={activeTextStyle}
                  >
                    {partnership.name}
                  </Typography>
                </Grid>
                <Grid item md={4} xl={4} borderColor={'primary.main'}>
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    fontWeight={'bold'}
                    textTransform='uppercase'
                    textAlign='left'
                    sx={activeTextStyle}
                  >
                    {partnership.occupation}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Box>
        {/* Keep this hidden until fixed with partner */}
        {/* <Box marginTop={10} width='100%' borderTop={'1px solid #fff'}>
        <Marquee gradient={false} speed={60} direction='right'>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/etherpoap.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/astar.png' />
          </Grid>
          <Grid item md={3} px={2} py={2}>
            <img src='/images/rainbowSue/hashkey.png' />
          </Grid>
        </Marquee>
      </Box> */}
      </Grid>
    </ScrollReveal>
  )
}

export default PartnershipDesktop
