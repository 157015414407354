import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import RainbowSue from 'views/RainbowSue'
import RainbowSueDoll from 'views/RainbowSue/Doll'

declare global {
  interface Window {
    // ecwid types
    ecwid_script_defer: boolean
    ecwid_sso_profile: string
    ecwid_dynamic_widgets: boolean
    ec: any
    _xnext_initialization_scripts: any
    ref: any
  }
  interface HTMLScriptElement {
    ref: any
  }
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<RainbowSue />} />
        <Route path='/dolls/:id' element={<RainbowSueDoll />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
