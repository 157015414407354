import useIsMobile from 'hooks/useIsMobile'
import AboutNftMobile from './AboutNftMobile'
import AboutNftDesktop from './AboutNftDesktop'

const AboutNft: React.FC = () => {
  const isMobile = useIsMobile()

  return isMobile ? <AboutNftMobile /> : <AboutNftDesktop />
}

export default AboutNft
