import { motion } from 'framer-motion'
import React, { ReactNode } from 'react'

type ScrollRevealProps = {
  children: ReactNode
  delay?: number
  duration?: number
}

export const ScrollReveal: React.FC<ScrollRevealProps> = ({
  children,
  delay = 0,
  duration = 1,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration, delay }}
    >
      {children}
    </motion.div>
  )
}
