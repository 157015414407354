import Axios from 'axios'
import { ILabelledError } from 'domain/types/LabelledError'
import { TUserRegistration } from 'domain/types/UserRegistration'

const GET_AUTH_URL_ENDPOINT = '/api/v1/auth/twitter/getAuthUrl'
const AUTHENTICATE_TWITTER_ENDPOINT = '/api/v1/auth/twitter/authenticate'
const SIGNIN_PATH = '/auth/login'
const SIGNIN_PATH_V2 = '/auth/login/internal'
const GOOGLE_SIGN_IN = '/auth/login/social/google'
const SIGNUP_URL = '/auth/register'
const SIGNUP_URL_V2 = '/auth/register/internal'

const CHANGE_PASSWORD = '/auth/changePassword'
const CONFIRM_SIGNUP_URL = '/auth/confirmSignup'
const FORGOT_PASSWORD_URL = '/auth/forgotPassword'
const FORGOT_PASSWORD_CONFIRM_URL = '/auth/forgotPassword/confirm'

const INITIATE_WALLET_SIGNIN_URL = '/auth/login/web3Wallet/init'
const WALLET_SIGNIN_URL = '/auth/login/web3Wallet'

const LOGOUT_PATH = '/auth/logout'

export enum EAuthSupportedAPIVersion {
  v1 = '/api/v1',
  v2 = '/api/v2',
}

type Options = {
  version: EAuthSupportedAPIVersion
}

export const AuthApi = {
  logOut: async function (options?: Options): Promise<boolean> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    await Axios.post(`${version}${LOGOUT_PATH}`)
    return true
  },

  signIn: async function (
    username: string,
    password: string,
    options?: Options,
  ): Promise<any> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    switch (version) {
      case EAuthSupportedAPIVersion.v1:
        try {
          return await Axios.post(`${version}${SIGNIN_PATH}`, {
            username,
            password,
          })
        } catch (e: any) {
          throw Error(e.response?.data?.message)
        }

      case EAuthSupportedAPIVersion.v2:
        try {
          return await Axios.post(`${version}${SIGNIN_PATH_V2}`, {
            loginId: username,
            password,
          })
        } catch (e: any) {
          const error = e.response?.data as ILabelledError
          throw Error(error?.error?.message)
        }
    }
  },

  googleSignIn: async function (credential: string): Promise<any> {
    try {
      return await Axios.post(
        `${EAuthSupportedAPIVersion.v2}${GOOGLE_SIGN_IN}`,
        {
          credential,
        },
      )
    } catch (e: any) {
      const error = e.response?.data as ILabelledError
      throw Error(error?.error?.message)
    }
  },

  signUp: async function (
    user: TUserRegistration,
    captchaToken: string,
    options?: Options,
  ): Promise<any> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    switch (version) {
      case EAuthSupportedAPIVersion.v1:
        try {
          return await Axios.post(`${version}${SIGNUP_URL}`, user, {
            headers: { 'captcha-token': captchaToken },
          })
        } catch (e: any) {
          throw Error(e.response?.data?.message)
        }

      case EAuthSupportedAPIVersion.v2:
        try {
          return await Axios.post(`${version}${SIGNUP_URL_V2}`, user, {
            headers: { 'captcha-token': captchaToken },
          })
        } catch (e: any) {
          const error = e.response?.data as ILabelledError
          throw Error(error?.error?.message)
        }
    }
  },

  changePassword: async function (
    oldPassword: string,
    newPassword: string,
    options?: Options,
  ): Promise<any> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    try {
      return await Axios.post(`${version}${CHANGE_PASSWORD}`, {
        oldPassword,
        newPassword,
      })
    } catch (e: any) {
      if (version === EAuthSupportedAPIVersion.v2) {
        const error = e.response?.data as ILabelledError
        throw Error(error?.error?.message)
      } else {
        throw Error(e.response?.data?.message)
      }
    }
  },

  confirmSignup: async function (
    username: string,
    otp: string,
    options?: Options,
  ): Promise<any> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    switch (version) {
      case EAuthSupportedAPIVersion.v1:
        try {
          return await Axios.post(`${version}${CONFIRM_SIGNUP_URL}`, {
            username,
            otp,
          })
        } catch (e: any) {
          throw Error(e.response?.data?.message)
        }
      case EAuthSupportedAPIVersion.v2:
        try {
          return await Axios.post(`${version}${CONFIRM_SIGNUP_URL}`, {
            loginId: username,
            otp,
          })
        } catch (e: any) {
          const error = e.response?.data as ILabelledError
          throw Error(error?.error?.message)
        }
    }
  },

  forgotPassword: async function (
    loginId: string,
    options?: Options,
  ): Promise<string> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    switch (version) {
      case EAuthSupportedAPIVersion.v1:
        try {
          const {
            data,
          }: {
            data: {
              CodeDeliveryDetails: {
                Destination: string
              }
            }
          } = await Axios.post(`${version}${FORGOT_PASSWORD_URL}`, {
            username: loginId,
          })
          return data.CodeDeliveryDetails.Destination
        } catch (e: any) {
          throw Error(e.response?.data?.message)
        }
      case EAuthSupportedAPIVersion.v2:
        try {
          const response = await Axios.post(
            `${version}${FORGOT_PASSWORD_URL}`,
            {
              loginId,
            },
          )
          return response.data.emailAddress
        } catch (e: any) {
          const error = e.response?.data as ILabelledError
          throw Error(error?.error?.message)
        }
    }
  },

  confirmForgotPassword: async function (
    loginId: string,
    code: string,
    password: string,
    captchaToken: string,
    options?: Options,
  ): Promise<any> {
    const version = options?.version || EAuthSupportedAPIVersion.v1
    switch (version) {
      case EAuthSupportedAPIVersion.v1:
        try {
          return await Axios.post(
            `${version}${FORGOT_PASSWORD_CONFIRM_URL}`,
            {
              username: loginId,
              code,
              password,
            },
            {
              headers: {
                'captcha-token': captchaToken,
              },
            },
          )
        } catch (e: any) {
          throw Error(e.response?.data?.message)
        }
      case EAuthSupportedAPIVersion.v2:
        try {
          return await Axios.post(
            `${version}${FORGOT_PASSWORD_CONFIRM_URL}`,
            {
              loginId,
              otp: code,
              newPassword: password,
            },
            {
              headers: {
                'captcha-token': captchaToken,
              },
            },
          )
        } catch (e: any) {
          const error = e.response?.data as ILabelledError
          throw Error(error?.error?.message)
        }
    }
  },

  initiateWalletSignIn: async function (): Promise<string> {
    try {
      const result = await Axios.post(
        `${EAuthSupportedAPIVersion.v2}${INITIATE_WALLET_SIGNIN_URL}`,
      )
      return result.data
    } catch (err: any) {
      console.error(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  walletSignIn: async function (
    address: string,
    message: string,
    signedMessage: string,
  ): Promise<string> {
    try {
      return await Axios.post(
        `${EAuthSupportedAPIVersion.v2}${WALLET_SIGNIN_URL}`,
        {
          address,
          message,
          signedMessage,
        },
      )
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  getAuthUrl: async function (): Promise<string> {
    try {
      const response = await Axios.get(GET_AUTH_URL_ENDPOINT)

      const url = response.data.url
      return url
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },

  authenticateTwitterHandle: async function (
    oauth_token: string,
    oauth_verifier: string,
  ): Promise<void> {
    try {
      await Axios.put(AUTHENTICATE_TWITTER_ENDPOINT, {
        oauth_token,
        oauth_verifier,
      })

      return
    } catch (err: any) {
      console.log(err)

      const message = err?.response?.data?.message
      if (message) throw Error(message)

      throw Error(err.message)
    }
  },
}
