import Axios from 'axios'
import { TNfc, TPbtClaimRequestBody } from 'constants/nfc'

const NFT_ENDPOINT = '/api/v1/nft'
const CLAIM_PBT_ENDPOINT = `${NFT_ENDPOINT}/claimPbt`

export const NftApi = {
  claimPbt: async function (data: TPbtClaimRequestBody): Promise<TNfc> {
    try {
      const result = await Axios.post(CLAIM_PBT_ENDPOINT, data)

      return result.data.nfc
    } catch (err: any) {
      console.log(err)
      const message = err?.response?.data?.message
      if (message) throw Error(message)
      throw Error('An error has occured when claiming PBT')
    }
  },
}
