import {
  Box,
  IconButton,
  Popover,
  Slide,
  styled,
  useTheme,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import React, { useEffect, useRef, useState } from 'react'
import NavDrawer from './Drawer'
import StartButton from './StartButton'

import useIsMobile from 'hooks/useIsMobile'
import { useSectionObserver } from 'hooks/RainbowSue/useSectionObserver'
import './header.css'

const HeaderBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  position: 'fixed',
  zIndex: 10000,
  display: 'flex',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.background.paper,

  margin: 'auto',
  top: 8,
}))

export const NavIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  ':hover': {
    background: 'url(/images/rainbowSue/Utitlies_disc_1.png)',
    backgroundSize: 'contain',
    cursor: 'pointer',
    color: '#000',
  },
  ':hover svg': {
    color: '#000',
  },
}))

const Header = ({ sections }: IProps) => {
  const isMobile = useIsMobile()

  const ref = useRef<HTMLElement>(null)

  const isLightMode = useTheme().palette.mode === 'light'

  const { section } = useSectionObserver(sections)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [inHero, setInHero] = useState<boolean>(true)

  useEffect(() => {
    section === 'hero' ? setInHero(true) : setInHero(false)
    return () => {}
  }, [section])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleShowDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <HeaderBox
        id='rsHeader'
        ref={ref}
        left={!isMobile ? 24 : 8}
        right={!isMobile ? 24 : 8}
        height={!isMobile ? '76px' : '48px'}
        className='fadeIn'
        sx={{
          justifyContent: inHero ? 'start' : 'space-between',
          alignItems: 'center',
          borderTop: 1,
          borderBottom: 1,
          borderColor: isLightMode ? 'secondary.main' : 'primary.main',
          pl: 2,
          transition: 'background .5s, border .5s ease',
          ...(isMobile && (inHero ? { py: '12px' } : { py: 1 })),
          ...(!inHero && {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          }),
        }}
      >
        {/* Icon Button for Drawer */}
        <NavIconButton
          aria-describedby={'menu-button'}
          aria-label='menu-button'
          size='large'
          sx={{
            ...(isLightMode &&
              inHero && {
                color: 'secondary.main',
                borderColor: 'secondary.main',
                backgroundColor: 'primary.main',
              }),
            ':hover': {
              borderColor: 'transparent',
            },
          }}
          onClick={(e) => handleShowDrawer(e)}
        >
          <MenuIcon color='inherit' />
        </NavIconButton>
        {/* Conditional Components redenring based on different sections */}
        {section === 'hero' && (
          <>
            <Box
              borderLeft={isMobile ? 'none' : '1px solid #fff'}
              mx={isMobile ? 0 : 2}
              my={isMobile ? 2 : 0}
              marginLeft={isMobile ? 'auto' : 3}
              height={'100%'}
              flex={1}
              display={'flex'}
              alignItems={'center'}
            >
              <img
                width='auto'
                height='50px'
                src='/images/rainbowSue/rsLogo.png'
                alt='rsLogo'
              />
            </Box>
          </>
        )}
        {!isMobile && (
          <StartButton inHero={inHero} noBorderRight={inHero}>
            Start your journey
          </StartButton>
        )}
      </HeaderBox>
      <Popover
        id='menu-button'
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={0}
        disableEnforceFocus={true}
        TransitionComponent={Slide}
        // Ignore due to typing not having direction as prop value eventhough it is valid
        // @ts-ignore
        TransitionProps={{ direction: 'right' }}
        marginThreshold={0}
        sx={{
          zIndex: 1000000,
          '.MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            color: isLightMode ? 'secondary.main' : 'primary.main',
            borderColor: isLightMode ? 'secondary.main' : 'primary.main',
            backgroundColor: isLightMode ? 'primary.main' : 'background.paper',
            height: '100vh',
            top: '0 !important',
            bottom: '0 !important',
            maxHeight: '100% !important',
            left: '0 !important',
            transition: 'background .5s, border .5s ease',
            ...(isMobile
              ? {
                  right: '0 !important',
                  minWidth: '100vw',
                  px: 0,
                }
              : {
                  px: 0,
                }),
          },
        }}
      >
        <NavDrawer handleClose={handleClose} />
      </Popover>
    </>
  )
}

export default Header

interface IProps {
  sections: string[]
}
