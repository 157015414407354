import { createReducer } from '@reduxjs/toolkit'
import { setToastState } from './actions'
import { AlertColor } from '@mui/material'

export type ToastType = {
  open?: boolean
  type: AlertColor
  message: string
  direction?: {
    horizontal: 'left' | 'center' | 'right'
    vertical: 'top' | 'bottom'
  }
  duration?: number
}

export interface ToastState {
  toastState: ToastType
}

export const initialState: ToastState = {
  toastState: {
    open: false,
    type: 'success',
    message: '',
    direction: {
      vertical: 'top',
      horizontal: 'right',
    },
    duration: 6000,
  },
}

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setToastState, (state, { payload }) => {
    state.toastState = { ...state.toastState, ...payload }
  })
})

export default userReducer
