import { ProfileApi } from './profile'
import { NfcApi } from './nfc'
import { NftApi } from './nft'
import { ProjectApi } from './project'
import { AuthApi } from './auth'
import { CampaignApi } from './campaign'
import { StoreApi } from './store'
import { InstagramAPI } from './instagram'

const Api = {
  profile: ProfileApi,
  project: ProjectApi,
  nfc: NfcApi,
  nft: NftApi,
  auth: AuthApi,
  campaign: CampaignApi,
  instagram: InstagramAPI,
  store: StoreApi,
}

export default Api
