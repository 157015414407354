import React, { useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Fade,
  Button,
  IconButton,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import ModalRainbowsue from 'components/RainbowSue/Modal'
import SectionHeader from './SectionHeader'
import { ContainedDiv, FullWidthDiv } from 'components/RainbowSue/SnapScrollDiv'
import { Remove } from '@mui/icons-material'
import { ScrollReveal } from './ScrollReveal'
import PixelSueMobile from './PixelSueMobile'

type SummaryInformation = {
  key: string
  value: string
}

const AboutNftMobile = () => {
  const [activeElement, setActiveElement] = useState<{
    id: number
    name: string
    description: string
    image: string
    summaryInformation: SummaryInformation[]
  } | null>(null)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const isLightMode = useTheme().palette.mode === 'light'

  const { t } = useTranslation()

  const elements = Object.entries(
    t('rainbowsue.productElements', { returnObjects: true }),
  ).map(([key, value]) => ({
    id: value.id,
    name: value.name,
    description: value.description,
    image: value.image,
    summaryInformation: value.summaryInformation,
  }))

  const onButtonClick = (id: string) => {
    handleOpen()
    const data = elements.find((element) => element.id === id)
    if (data) {
      setActiveElement(data)
    }
  }

  return (
    <React.Fragment>
      <Grid container sx={{ pt: 1 }}>
        <SectionHeader title={'About'} alignRight disableBorder mb={'0'} />
        <ScrollReveal>
          <FullWidthDiv
            display='flex'
            borderTop='1px solid #fff'
            borderBottom='1px solid #fff'
            py={2}
          >
            <ContainedDiv sx={{ marginLeft: 0 }}>
              <Typography
                variant='subtitle1'
                color='primary'
                textTransform='uppercase'
              >
                Rainbowsue NFT
              </Typography>
            </ContainedDiv>
          </FullWidthDiv>
          <FullWidthDiv>
            <ContainedDiv py={3} pb='54px'>
              <Typography variant='body1' color='primary'>
                {t('rainbowsue.description')}
              </Typography>
            </ContainedDiv>
          </FullWidthDiv>
        </ScrollReveal>
        <PixelSueMobile />

        <Box
          display='flex'
          borderTop='1px solid #fff'
          borderBottom='1px solid #fff'
          width='100%'
          py={2}
          px={3}
        >
          <Typography
            width='100%'
            variant='subtitle1'
            color='primary'
            textTransform='uppercase'
            textAlign='center'
          >
            Meet the 4 tribes of rainbowsue
          </Typography>
        </Box>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className={`rainbowsue__swiper ${
            isLightMode
              ? ' rainbowsue__swiper__light'
              : ' rainbowsue__swiper__dark'
          }`}
        >
          {elements.map((element) => (
            <SwiperSlide key={`element-mobile-${element.id}`}>
              <Grid item xs={12} md={4} pt={'40px'}>
                <Box
                  my={1}
                  mx={1}
                  flexDirection={'column'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <img
                    src={element.image}
                    alt={element.image}
                    style={{
                      maxHeight: '500px',
                    }}
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{
                      width: '172px',
                      height: '44px',
                      borderRadius: '0 !important',
                      textAlign: 'center',
                      mt: 2,
                      mb: 5,
                    }}
                    onClick={() => onButtonClick(element.id)}
                  >
                    {element.name}
                  </Button>
                </Box>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
      <ModalRainbowsue open={open} handleClose={handleClose}>
        <Fade in={open}>
          <Grid container>
            <Grid item xs={6}>
              <IconButton
                aria-label='hamburgerBar'
                size='large'
                sx={{ border: '1px solid #fff', height: 'fit-content' }}
                onClick={() => handleClose()}
              >
                <Remove color='primary' />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={6}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Typography
                variant='h1'
                color='primary'
                textTransform='uppercase'
              >
                ABOUT
              </Typography>
            </Grid>
            <Grid
              item
              my={2}
              px={1}
              py={1}
              xs={12}
              borderTop='1px solid #fff'
              borderBottom='1px solid #fff'
            >
              <Typography
                variant='subtitle1'
                color='primary'
                textTransform='uppercase'
              >
                {activeElement?.name}
              </Typography>
            </Grid>
            <Grid item my={2} py={1} px={1} xs={12}>
              <Typography color='primary' textAlign='justify'>
                {activeElement?.description}
              </Typography>
            </Grid>
            {activeElement?.summaryInformation?.map((information, index) => (
              <Grid
                item
                pt={'6px'}
                pb={'1px'}
                px={1}
                xs={12}
                borderTop={index === 0 ? '1px solid #fff' : 'none'}
                borderBottom='1px solid #fff'
                key={`element-information-${information.key}`}
              >
                <Typography variant='h2' color='primary' textAlign='justify'>
                  {information.key}
                </Typography>
                <Typography
                  variant='subtitle1'
                  color='primary'
                  textAlign='justify'
                >
                  {information.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Fade>
      </ModalRainbowsue>
    </React.Fragment>
  )
}

export default AboutNftMobile
