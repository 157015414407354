import { Box, styled, keyframes } from '@mui/material'
import { useScrollingEffect } from 'hooks/RainbowSue/useScrollingEffect'
import useIsMobile from 'hooks/useIsMobile'
import React, { useEffect } from 'react'

// const HeroSection: React.FC<{ showHeader: Function }> = ({ showHeader }) => {
//   const vidRef = useRef(null)
//   const [videoStarted, setVideoStarted] = useState(false)
//   const isMobile = useIsMobile()

//   const triggerVideoPlay = useCallback(
//     (e: any) => {
//       const currentVidRef = vidRef?.current as any
//       if (!videoStarted) {
//         setVideoStarted(true)
//         currentVidRef?.play()
//       }
//     },
//     [vidRef, videoStarted, setVideoStarted],
//   )

//   useEffect(() => {
//     window.addEventListener(isMobile ? 'touchmove' : 'wheel', triggerVideoPlay)
//     return () => {
//       // return a cleanup function to unregister our function since it will run multiple times
//       window.removeEventListener(
//         isMobile ? 'touchmove' : 'wheel',
//         triggerVideoPlay,
//       )
//     }
//   }, [vidRef, videoStarted, setVideoStarted, triggerVideoPlay, isMobile])

//   return (
//     <Box
//       sx={{
//         height: '100vh',
//       }}
//     >
//       <video
//         ref={vidRef}
//         playsInline
//         muted
//         width='100%'
//         height='100%'
//         onPlay={() =>
//           setTimeout(() => {
//             showHeader()
//           }, 2750)
//         }
//         style={{ objectFit: 'cover' }}
//         poster={
//           isMobile ? '/images/rainbowSue/mobile-hero-placeholder.png' : ''
//         }
//       >
//         {isMobile ? (
//           <source
//             src='images/rainbowSue/mobileRsHeroSection.mp4'
//             type='video/mp4'
//           />
//         ) : (
//           <>
//             <source
//               src='images/rainbowSue/rsHeroSection.webm'
//               type='video/webm'
//             />
//             <source
//               src='images/rainbowSue/rsHeroSection.mp4'
//               type='video/mp4'
//             />
//           </>
//         )}
//       </video>
//       <Box
//         sx={{
//           display: !videoStarted ? 'block' : 'none',
//           width: 'fit-content',
//           position: 'absolute',
//           bottom: 45,
//           margin: 'auto',
//           left: '50%',
//           transform: 'translate(-50%, 0)',
//         }}
//       >
//         <img src='images/rainbowSue/scrolldown.gif' height={'175px'} alt='' />
//       </Box>
//     </Box>
//   )
// }`

const slideDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(80%);
  }
`

const Mouse = styled('div')({
  height: '48px',
  width: '32px',
  border: '2px solid white',
  borderRadius: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingTop: '4px',
})

const MouseScoll = styled('div')({
  height: '16px',
  width: '2px',
  borderRadius: '25px',
  backgroundColor: 'white',
  animation: `${slideDown} 1s ease infinite`,
})

const HeroSectionWithScrollingEffect: React.FC<{
  showHeader: Function
  id: string
}> = ({ showHeader, id }) => {
  const isMobile = useIsMobile()

  const { isEnded } = useScrollingEffect()

  useEffect(() => {
    isEnded && showHeader()
    return () => {}
  }, [isEnded, showHeader])

  return (
    <>
      <Box
        sx={{
          display: isEnded ? 'none' : 'block',
          width: 'fit-content',
          position: 'fixed',
          bottom: 0,
          margin: 'auto',
          left: '50%',
          transform: 'translate(-50%, 0)',
          marginBottom: '40px',
          zIndex: 999,
        }}
      >
        <Mouse>
          <MouseScoll />
        </Mouse>
      </Box>
      <Box
        id={id}
        sx={{
          backgroundColor: 'background.paper',
          minHeight: !isMobile ? '100vh' : '-webkit-fill-available',
          maxHeight: '100%',
          height: '500vh',
        }}
      >
        <canvas
          id='heroCanvas'
          style={{
            width: '100%',
            height: '100vh',
            position: 'sticky',
            top: 0,
            objectFit: 'cover',
            objectPosition: '50% 50%',
          }}
        />
      </Box>
    </>
  )
}

export default HeroSectionWithScrollingEffect
