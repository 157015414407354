import { styled } from '@mui/material'
import { ComponentPropsWithoutRef } from 'react'

import { mintingUrl } from '../../constants/rainbowSue'

type BaseButtonProps = {
  inHero: boolean
  noBorderRight?: boolean
}

const BaseButton = styled('a')<BaseButtonProps>(
  ({ theme, inHero, noBorderRight }) => ({
    padding: '24px 36px',
    backgroundColor: inHero ? 'transparent' : theme.palette.background.paper,
    border: inHero
      ? '1px solid white'
      : `1px solid ${theme.palette.primary.main}`,
    borderRight: noBorderRight ? 'none' : undefined,
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
    color: inHero ? 'white' : theme.palette.primary.main,
    height: '78px',
    maxWidth: '344px',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    display: 'inline-block',
    lineHeight: '2',
    boxSizing: 'border-box',
    textDecoration: 'none',
    transition: 'all 0.3s',
    '&:hover': !inHero
      ? {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.background.paper,
        }
      : undefined,
    [theme.breakpoints.down('md')]: {
      padding: '12px 32px',
      height: 'auto',
      maxWidth: 'unset',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
)

type ButtonProps = ComponentPropsWithoutRef<'a'> & {
  inHero?: boolean
  noBorderRight?: boolean
}

const Button = ({
  children,
  inHero = false,
  noBorderRight,
  ...props
}: ButtonProps) => {
  return (
    <BaseButton
      inHero={inHero}
      noBorderRight={noBorderRight}
      href={mintingUrl}
      target='_blank'
      rel='noreferrer'
      {...props}
    >
      {children}
    </BaseButton>
  )
}

export default Button
